<template>
	<div>
		<div class="modal fade" id="unionObjectModal" ref="unionObjectModal" tabindex="-1" aria-labelledby="Изменить" aria-hidden="true">
			<div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div  class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span v-if="is_loaded" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Объекты
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <template v-if="is_loaded">
                        
                        <SelectBox 
								   inputId="object"
								   inputName="object"
								   ref="object"
								   inputLabel="Объект:"
								   :inputOptions="this.objects_list"
								   :inputValue="this.object_id"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputSearch="true"
								   inputMultiple="false"
								   inputPlaceholder="Выберите объект"
								   inputLazyLoad="true"
								   inputRequired="true"
								   @searchInput="(term) => this.objects_search_query = term"
								   @endOfList="(length) => this.objects_offset = length"
								   />
                              
                    </template>                 
                    <Loader v-else />
					<!--<div v-if="is_loaded" class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								:disabled="!this.object_id"
								@click="saveStatus()" >
							Сохранить
						</button>
					</div>-->

				</div>
                
			</div>
            
		</div>
		
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";
import Loader from '@/components/common/Loader.vue';
import SelectBox from "@/components/inputs/SelectBox";


export default {
	name: 'UnionObjectModal',
	components: { Loader, SelectBox },
	data() {
		return {
			object_id: null,
            is_loaded: false,
            objects_list: [],
            objects_search_query: '',
            objects_offset: 0
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
        inArray(needle, haystack) {
            return CommonService.inArray(needle, haystack);
        },
        formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},

        getObjectsList(objects_ids, with_groups) {
            console.log(objects_ids)
            if (typeof objects_ids == "undefined" && !this.isEmpty(this.object_id))
                objects_ids = [this.object_id];

            let params = {
                section: 'union',
                search_query: this.objects_search_query,
                objects_ids: objects_ids ?? null
            }

           
            CommonService.log('debug', 'getObjectsList()', params);

            return api.get('/objects/list', {
                params: params
            })
            .then((response) => {

                

                //CommonService.log('debug', 'getObjectsList()::axios', {response: response.data});
                console.log(response.data.list);
                if (response.status == 200 && response.data.success) {

                    let list = [];
                    let groups = (response.data.groups) ? response.data.groups : [];

                    if (response.data.list) {
                        Object.entries(response.data.list).forEach(([key, object]) => {

                            if (object.group_id && with_groups) {

                                let group_id = object.group_id;
                                if (groups[group_id]) {

                                    list.push({
                                        value: false,
                                        name: '<b>' + groups[group_id] + '</b>'
                                    });

                                    delete groups[group_id];
                                }
                            }

                            if (this.isEmpty(this.objects_list.filter(item => item.value === object.id))) {
                                list.push({
                                    value: object.id,
                                    name: object.title + ' (ID '+object.id+')<br/><small class="text-secondary">' + object.address + '</small><br/><small class="text-primary">' + this.formatPrice(object.price) + ' руб.</small>'
                                });
                            }
                        });
                    }

                    return list;
                } else {
                    return {};
                }

            }).catch(function (error) {

                CommonService.log('error', 'getObjectsList()::axios', error);

                //this.is_loaded = false;
            });
        },
        getListStatus: function(){
            return api.get('/object/statusLists',{
                params: {
					object_id: this.object_id,
                    
				}
                })
				.then((response) => {
                  //  console.log(response.data);
					CommonService.log('debug', 'getRoles()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let listStatus = [];
						if (response.data.status_lists) {
							Object.entries(response.data.status_lists).forEach(([key, status]) => {
								listStatus.push({
									value: status.id.toString(),
									name: status.name
								});
							});
						}
                        this.status_list = listStatus; 
                        this.is_loaded = true;
                       
						return listStatus;
					} else {
						return {};
					}

                    
				}).catch(function (error) {

					CommonService.log('error', 'getListMebel()::axios', error);

				});
        },
        
        
        saveStatus: function (){

            let data = {
					
					object_id: this.object_id,
                    status_id: this.status_id,
					
				}

				CommonService.log('debug', data);

				if (!this.isEmpty(this.status_id) && !this.isEmpty(this.object_id)) {

					

					let _this = this;
					api.put('/object/setStatus', data).then((response) => {
						//CommonService.log('debug', 'saveService()::axios', {response});
                        console.log(response.data);
						if (response.status == 200 && response.data.success) {
							
                            _this.$emit('updateObject', _this.object_id);

                            let modal = CommonService.getModal('unionObjectModal');
                            if (modal)
                                modal.hide();
              
						}
					}).catch(function (error) {

						CommonService.log('error', 'saveService()::axios', error);

					});
				}
        },

        icon(path, class_name, title, size) {
            return CommonService.buildIconImage(path, class_name, title, size);
        },
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.unionObjectModal;
        
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
                _this.status_id = null;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.results = [];
				_this.errors = [];

				let object_id = target.getAttribute('data-bs-object-id');
				if (typeof object_id !== "undefined") {
					_this.object_id = object_id;
				}

               

                if(!_this.isEmpty(_this.object_id)){
                    _this.getObjectsList([_this.object_id]).then(list => _this.objects_list = list);
                    _this.is_loaded = true;
						
                }

				if (process.env.NODE_ENV == "development") {
					console.debug('unionObjectModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		
	},
}
</script>