<template>
  <div v-if="!this.item.is_deleted"
       :id="!this.isEmpty(this.item.id) ? 'item_' + this.item.id : false"
       class="card object-item mb-3"
       :class="(this.item.is_viewed && this.section !== 'autosearch') ? 'viewed' : ''"
       @click="this.setViewed(this.item.id)"
       @focus="this.setViewed(this.item.id)"
       @blur="this.setViewed(this.item.id)"
       @mouseenter="this.setViewed(this.item.id)"
       @mousemove="this.setViewed(this.item.id)">
    <div class="card-img-top d-flex justify-content-center w-100">
      <div v-if="!this.isEmpty(this.item.photos)"
           :id="'objectThumbnails' + this.item.id"
           class="carousel slide"
           data-bs-touch="true" 
           data-bs-interval="false">
        <div class="carousel-inner">
          <div v-for="(photo, index) of this.item.photos"
               :key="this.item.id + '_photo_' + index"
               :class="['carousel-item', (index == 0) ? 'active' : '']"
               :style="`background-image: url('` + photo.thumbnail + `');`"
               :title="'Фото ' + (index+1) + ' из ' + this.photo_count">
            <img :src="photo.thumbnail" class="d-none" />
          </div>
        </div>
        <button v-show="this.photo_count > 1"
                class="carousel-control-prev mt-4"
                type="button"
                :data-bs-target="'#objectThumbnails' + this.item.id"
                data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Предыдущее фото</span>
        </button>
        <button v-show="this.photo_count > 1"
                class="carousel-control-next mt-4"
                type="button"
                :data-bs-target="'#objectThumbnails' + this.item.id"
                data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Следующее фото</span>
        </button>
      </div>
      <img v-else :src="require('@/assets/icons/no-image.svg')" class="d-inline-flex" alt="Нет фото" title="Нет фото"/>

      <span v-if="!this.isEmpty(this.item.author)"
            class="badge badge-sticker" v-html="(this.item.author.is_agent) ? 'Агент' : 'Собственник'"/>

      <button type="button"
              class="btn btn-add-favor"
              :class="(this.item.is_favoured || this.$props.isFavoured) ? 'active' : ''"
              @click="this.addRemoveObjectFavour(this.item.id)">
        <img v-if="this.item.is_favoured" :src="require('@/assets/icons/heart-fill.svg')" alt="Убрать из избранного" width="24" height="24">
        <img v-else :src="require('@/assets/icons/heart.svg')" alt="В избранное" width="24" height="24">
      </button>
      <button v-if="this.$props.layout == 'search'" type="button"
              class="btn btn-add-viewed"
              :class="(this.item.is_viewed) ? 'active' : ''"
              @click="this.addRemoveObjectViewed(this.item.id)">
        <img v-if="this.item.is_viewed" :src="require('@/assets/icons/view.svg')" alt="В просмотренные" width="24" height="24">
        <img v-else :src="require('@/assets/icons/view.svg')" alt="Убрать из просмотренных" width="24" height="24">
      </button>
      <button v-if="this.$props.layout == 'short' && this.section == 'autosearch'" type="button"
              class="btn btn-add-viewed"
              @click="this.addObjectToIgnored(this.item.id)">
        <img :src="require('@/assets/icons/delete.svg')" alt="В игнорируемые" width="24" height="24">
      </button>
    </div>
    
    <div class="card-body position-relative overflow-visible">
      <div class="d-flex align-content-between meta flex-wrap pt-2">
        <!--<span class="d-inline-flex ms-0 fs-5">{{ this.formatDateTime(this.item.published_at, 'ru', 'LL') }}</span>
        <span class="d-inline-flex fs-5" v-text="(this.item.type == 0) ? 'Сдаётся' : 'Продается'"/>-->
        <span class="d-inline-flex lw-apartment-card__id"
              @click="this.copyID(this.item.id)">ID {{ this.item.id }}</span>
        <template v-if="!this.isEmpty(this.item.status_name)" >      
          <span v-if="this.item.is_archived" data-bs-toggle="modal" 
                    @click="openTask"
                    class="lw-base-text--small lw-text-green lw-apartment-card__status fs-4 archive">{{ this.item.status_name }}</span>
          <span v-else data-bs-toggle="modal" 
                    data-bs-target="#updateStatusModal" 
                    :data-bs-status-id="this.item.status_object_id" 
                    :data-bs-object-id="this.item.id"
                    class="lw-base-text--small lw-text-green lw-apartment-card__status fs-4">{{ this.item.status_name }}</span>
        </template>          
        <div v-if="this.action_menu_show" class="ms-auto dropdown dropdown dropstart ms-auto me-0 d-flex">
          <router-link v-if="this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" :to="'/edit-object?type_id=4&id=' + this.item.id" class="dropdown-item py-2 edit-obj-link" :id="'edit-obj-link' + this.item.id">
            <img :src="require('@/assets/icons/setting.svg')" class="me-2" alt="Редактировать" width="16" height="16"/>
          </router-link>
          <button type="button"
                  class="btn btn-menu dropdown-toggle px-0"
                  data-bs-toggle="dropdown"
                  data-bs-offset="-8,6"
                  aria-expanded="false">
            <img :src="require('@/assets/icons/dots-horz.svg')" alt="..." width="24" height="20" style="margin-bottom: 3px;" />
          </button>
          <div class="dropdown-menu rounded-1 border-0 p-0 overflow-visible"
               aria-labelledby="objectMenuAction"
               style="box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);">
            <img :src="require('@/assets/icons/dropdown-side-arr-icon.svg')" alt="" width="18" height="14" aria-hidden="true"
                 style="position: absolute; top: 16px; right: -9px; z-index: -1" />
            <ul class="list-group border-0 rounded-1 overflow-hidden">
              <li v-if="this.user_can.view.ads_report" class="list-group-item m-0 p-0">
                <a href="javascript:{}"
                   class="dropdown-item py-2"
                   data-bs-toggle="modal"
                   data-bs-target="#advertStatsModal"
                   :data-bs-object-id="this.item.id">
                  <img :src="require('@/assets/icons/schedule.svg')" class="me-2" alt="Отчет" width="16" height="16"/> Отчет по рекламе
                </a>
              </li>
              <li v-if="this.user_can.view.publish_report" class="list-group-item m-0 p-0">
                <a href="javascript:{}"
                   class="dropdown-item py-2">
                  <img :src="require('@/assets/icons/schedule.svg')" class="me-2" alt="Отчет" width="16" height="16"/> Отчет о публикации
                </a>
              </li>
              <li v-if="this.user_can.view.payment_report" class="list-group-item m-0 p-0">
                <a href="javascript:{}"
                   class="dropdown-item py-2"
                   data-bs-toggle="modal"
                   data-bs-target="#advertCoastModal"
                   :data-bs-object-id="this.item.id">
                  <img :src="require('@/assets/icons/coin.svg')" class="me-2" alt="Затраты" width="16" height="16"/> Затраты на рекламу
                </a>
              </li>
              <!--							<li v-if="this.user_can.copy" class="list-group-item m-0 p-0">-->
              <li v-if="this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" class="list-group-item m-0 p-0">
                <a href="javascript:{}"
                   class="dropdown-item py-2"
                   @click="this.copyToMyObjects()">
                  <img :src="require('@/assets/icons/floppy-icon.svg')" class="me-2" alt="Копировать" width="16" height="16"/> Копировать
                </a>
              </li>
              <!--						<li v-if="this.user_can.view.cabinet" class="list-group-item m-0 p-0">
                              <a href="javascript:{}"
                                 class="dropdown-item py-2 opacity-50"
                                 @click="this.copyLink()">
                                <img :src="require('@/assets/icons/avatar.svg')" class="me-2" alt="Кабинет" width="16" height="16"/> Кабинет клиента
                              </a>
                            </li>-->
              <!--							<li v-if="this.user_can.edit" class="list-group-item m-0 p-0">-->

<!--              <li v-if="this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" class="list-group-item m-0 p-0">-->
<!--&lt;!&ndash;              <li v-if="this.item.this_user_can_edit || this.$store.getters.userInfo.permissions.menu_all_objects_edit == 1 || this.$store.getters.userInfo.id == this.item.add_user_id || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" class="list-group-item m-0 p-0">&ndash;&gt;-->
<!--                <router-link :to="'/edit-object?type_id=4&id=' + this.item.id" class="dropdown-item py-2 edit-obj-link" :id="'edit-obj-link' + this.item.id">-->
<!--                  <img :src="require('@/assets/icons/setting.svg')" class="me-2" alt="Редактировать" width="16" height="16"/> Редактировать-->
<!--                </router-link>-->
<!--              </li>-->
              <li v-if="this.user_can.requisition" class="list-group-item m-0 p-0">
                <router-link :to="'/add-requisition?type_id=4&object_id=' + this.item.id" class="dropdown-item py-2">
                  <img :src="require('@/assets/icons/requisitions-icon.svg')" class="me-2" alt="Создать заявку" width="16" height="16"/> Создать заявку
                </router-link>
              </li>
              <!--						<li v-if="this.user_can.docs" class="list-group-item m-0 p-0">
                              <router-link :to="'#'" class="dropdown-item py-2 opacity-50">
                                <img :src="require('@/assets/icons/doc-icon.svg')" class="me-2" alt="Документы" width="16" height="16"/> Документы
                              </router-link>
                            </li>-->
              <li v-if="this.user_can.delete" class="list-group-item m-0 p-0">
                <a href="javascript:{}" class="dropdown-item py-2"
                   data-bs-toggle="modal"
                   :data-bs-target="'#' + this.confirmModalState.fromSection + this.item.id"
                   @click="fillConfirmModalState('deleteObject')">
                  <img :src="require('@/assets/icons/delete.svg')" class="me-2" alt="Удалить" width="16" height="16"/> Удалить
                </a>
              </li>
              <li v-if="this.user_can.send_complaint" class="list-group-item m-0 p-0">
                <a href="javascript:{}" class="dropdown-item py-2"
                   data-bs-toggle="modal"
                   :data-bs-target="'#submitComplaintModal'"
                   :data-bs-object-id="this.item.id">
                  <img :src="require('@/assets/icons/attention.svg')" class="me-2" alt="Пожаловаться" width="16" height="16"/> Пожаловаться
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div v-if="this.section !== 'autosearch'" style="float:right" class="pt-1 lw-main-btn lw-main-btn--no-border lw-text-green lw-apartment-card__commercial-btn">
       
        <button v-if="!this.isEmpty(this.item.object_union)"
            data-bs-toggle="modal"
            data-bs-target="#unionObjectModal"
            :data-bs-object-id="this.item.object_union[0]"
            type="button" class="lw-main-btn lw-apartment-card__further-btn">
          <img  :src="require('@/assets/icons/link.svg')" alt="link" width="36" height="36">
        </button>          
        <template v-if="this.item.use_in_advert">          
        <a v-if="this.item.use_in_advert && (!this.advert_error && !this.zipal_error && !this.packages_error)" href="javascript:{}"
           class="text-primary text-decoration-underline fs-4 "
           data-bs-toggle="popover"
           :data-bs-target="'#advertInfo' + this.item.id"
           data-bs-offset="30,10"
           data-bs-placement="bottom"
           data-bs-class="start-0 end-0 p-4 shadow"
           data-bs-container=".object-item .card-body"
           data-bs-body-class="w-100 pt-2 pe-3">
          В рекламе
        </a>
        <a v-else-if="this.item.use_in_advert" href="javascript:{}"
           class="text-danger text-decoration-underline fs-4"
           data-bs-toggle="popover"
           :data-bs-target="'#advertInfo' + this.item.id"
           data-bs-offset="30,10"
           data-bs-placement="bottom"
           data-bs-class="start-0 end-0 p-4 shadow"
           data-bs-container=".object-item .card-body"
           data-bs-body-class="w-100 pt-2 pe-3">
          В рекламе
        </a>
        <a v-else href="javascript:{}" class="text-dark-gray text-decoration-underline fs-4">
          Не рекламируется
        </a>
        </template>
      </div>
      <div v-if="this.section !== 'autosearch' && !this.isEmpty(this.item.services.advertised) && this.item.use_in_advert" class="d-none">
        <div :id="'advertInfo' + this.item.id">
          <dl class="list-unstyled p-3">

            <dt>Пакеты</dt>
            <dd v-if="this.item.services.advertised.packages" class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-success me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/checked.svg')" class="m-auto" alt="Размещается" width="8" height="8">
							</span>
              Размещается
              <ul v-if="this.item.services.advertised.packages">
                <li v-for="(packg, index) of this.published_packages" :key="index" class="list-unstyled">
                  {{ packg }}
                </li>
              </ul>
            </dd>
            <dd v-else class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-danger me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/close-white-icon.svg')" class="m-auto" alt="Не размещается" width="8" height="8">
							</span>
              Не размещается
            </dd>

            <dt>С отчетами</dt>
            <dd v-if="this.item.services.advertised.zipal" class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-success me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/checked.svg')" class="m-auto" alt="Размещается" width="8" height="8">
							</span>
              Размещается
            </dd>
            <dd v-else class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-danger me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/close-white-icon.svg')" class="m-auto" alt="Не размещается" width="8" height="8">
							</span>
              Не размещается
            </dd>

            <dt>Выгрузки через xml</dt>
            <dd class="pt-2">
              <img v-if="this.item.services.advertised.avito"
                   :src="require('@/assets/svg/avito-logo.svg')"
                   alt="Avito"
                   class="ms-0 me-2"
                   height="24" />
              <img v-if="this.item.services.advertised.cian"
                   :src="require('@/assets/svg/cian-logo.svg')"
                   alt="ЦИАН"
                   class="ms-0 me-2"
                   height="24" />
              <img v-if="this.item.services.advertised.yandex"
                   :src="require('@/assets/svg/yandex-logo.svg')"
                   alt="Яндекс.Недвижимость"
                   class="ms-0 me-2"
                   height="24" />
              <img v-if="this.item.services.advertised.domclick"
                   :src="require('@/assets/svg/domclick-logo.svg')"
                   alt="ДомКлик"
                   class="ms-0 me-2"
                   height="24" />
              <img v-if="this.item.services.advertised.emls"
                   :src="require('@/assets/svg/emls-logo.svg')"
                   alt="EMLS"
                   class="ms-0 me-2"
                   height="22" />
              <img v-if="this.item.services.advertised.jcat"
                   :src="require('@/assets/svg/jcat-logo.svg')"
                   alt="JCat"
                   class="ms-0 me-2"
                   height="22" />
            </dd>

            <dt>Бесплатно</dt>
            <dd v-if="this.item.services.advertised.free" class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-success me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/checked.svg')" class="m-auto" alt="Размещается" width="8" height="8">
							</span>
              Размещается
            </dd>
            <dd v-else class="d-inline-flex align-items-center">
							<span class="d-flex align-items-center text-center p-1 rounded-circle bg-danger me-2" style="width: 1.25rem; height: 1.25rem;">
								<img :src="require('@/assets/icons/close-white-icon.svg')" class="m-auto" alt="Не размещается" width="8" height="8">
							</span>
              Не размещается
            </dd>

            <dt v-if="!this.isEmpty(this.item.services.errors)">Ошибки размещения</dt>
            <dd v-if="!this.isEmpty(this.item.services.errors)" class="d-inline-flex align-items-center">
              <ul v-if="!this.isEmpty(this.item.services.errors.avito)" class="list-unstyled ms-3 mt-1 mb-0">
                <li v-for="(error, index) in this.item.services.errors.avito" :key="index" class="error-text" v-html="error"/>
              </ul>
              <ul v-if="!this.isEmpty(this.item.services.errors.cian)" class="list-unstyled ms-3 mt-1 mb-0">
                <li v-for="(error, index) in this.item.services.errors.cian" :key="index" class="error-text" v-html="error"/>
              </ul>
              <ul v-if="!this.isEmpty(this.item.services.errors.yandex)" class="list-unstyled ms-3 mt-1 mb-0">
                <li v-for="(error, index) in this.item.services.errors.yandex" :key="index" class="error-text" v-html="error"/>
              </ul>
              <ul v-if="!this.isEmpty(this.item.services.errors.domclick)" class="list-unstyled ms-3 mt-1 mb-0">
                <li v-for="(error, index) in this.item.services.errors.domclick" :key="index" class="error-text" v-html="error"/>
              </ul>
              <ul v-if="!this.isEmpty(this.item.services.errors.zipal)" class="list-unstyled ms-3 mt-1 mb-0">
                <li class="error-text" v-text="this.item.services.errors.zipal"/>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
      <div v-if="!this.isEmpty(this.item.funnel)" class="meta pt-1">
        <div class="d-flex align-items-center mb-20">
          <button
              type="button"
              class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10"
              data-bs-toggle="modal" data-bs-target="#changeStage2"
          >
            <img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20">
          </button>
          <div class="d-flex flex-column">
            <span class="text-font-secondary fs-4">Этап воронки:</span>
            <span>{{ this.item.funnel.name }}</span>
          </div>
        </div>
      </div>
      <div v-if="this.user_can.tags" class="meta pt-2 d-flex flex-row mb-10">
        <div v-if="!this.isEmpty(this.checked_tags)"
             class="d-flex flex-fill align-items-center ms-0 me-auto mw-60">
          <div class="d-flex border bg-light-gray rounded-circle p-2 me-10">
            <img :src="require('@/assets/icons/tag-icon.svg')" alt="Теги" width="12" height="12">
          </div>
          <ul class="d-inline-flex align-items-center flex-nowrap text-nowrap-mask scroller scroller-x mb-0 px-0 pe-4">
            <li class="fs-5 text-ocean"
                v-for="(tag, index) of this.checked_tags"
                :key="tag.id">
              <span :data-tag-id="tag.id" v-text="(index > 0) ? ', ' + tag.name : tag.name"/>
            </li>
          </ul>
        </div>
        <div class="d-flex flex-shrink-0 ms-auto me-0">
          <a href="javascript:{}" class="btn d-flex align-items-center p-0"
             data-bs-toggle="modal"
             data-bs-section="objects"
             :data-bs-object-id="this.item.id"
             :data-bs-tags-list="JSON.stringify(this.tags)"
             data-bs-target="#setTagsModal">
						<span class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1"
                  style="width:1.875rem; height:1.875rem;">
							<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="10" height="10">
						</span>
            <span class="text-dark fs-5 ps-2">Добавить тег</span>
          </a>
        </div>
      </div>
      <template v-if="(this.$props.layout == 'adverts' || this.$props.layout == 'default' || this.$props.layout == 'search') && !this.isEmpty(this.item.title)" >
        <div class="card-title form-check me-auto d-flex align-items-center">
          <input class="form-check-input flex-shrink-0 mt-0 mb-auto me-10 rounded-1"
                type="checkbox"
                value="1"
                :id="'objectChecked' + this.item.id"
                :checked="this.$props.isChecked ? true : false"
                @change="this.$emit('objectChecked', this.item.id)">
          <label class="form-check-label h3 fw-semi font-semi text-black mb-0" :for="'objectChecked' + this.item.id">
            {{ this.item.address }}
          </label>
          
        </div>
        <div class="lw-apartment-card__chars">
          {{ this.item.title }}
                              
        </div>
      </template>
      
      <div v-else class="card-title form-check me-auto d-flex align-items-center ps-0">
				<span class="form-check-label h3 fw-semi font-semi text-black mb-0">
					{{ this.item.title }}
					<small v-if="this.section == 'autosearch' && (this.is_viewed || this.item.is_viewed)" class="badge border border-warning text-warning fs-5 rounded-3">Просмотрено</small>
				</span>
      </div>
      
      <div v-if="!this.isEmpty(this.item.metro.stations)" class="col">
            <ul class="list-unstyled text-nowrap-mask me-2 mb-0">
              <template v-for="(metro, index) in this.item.metro.stations" :key="metro.id">
               
              
              <li v-if="index==0"
                  class="py-1 text-black align-items-start">
                <img :src="require('@/assets/icons/spb-metro.svg')" class="me-2" alt="Станция метро" width="20" height="20" />
                {{ metro.station }}
                <span v-if="!this.isEmpty(metro.subway_time)"
                  class="text-dark-gray">{{ metro.subway_time }} мин. 
                  <span v-if="!this.isEmpty(metro.subway_distance) && parseInt(metro.subway_distance) == 1">пешком</span>
                  <span v-else-if="!this.isEmpty(metro.subway_distance) && parseInt(metro.subway_distance) == 2">на транспорте</span>
                </span>
              </li>

              
                
              </template>
            </ul>
          </div>
      <div class="card-text">
        <div class="row row-cols-2">
          <div class="col">
            <ul class="list-unstyled text-nowrap-mask me-2 lw-apartment-card__list lw-apartment-card__list--chars">
              <li v-if="!this.isEmpty(this.item.rooms.komnat) && this.item.rooms.komnat != 0" class="lw-main-text">
                <span>Комнат:</span>
                <span>{{ this.item.rooms.komnat }}</span>
              </li>
              <li v-if="!this.isEmpty(this.item.rooms.bedroom) && this.item.rooms.bedroom != 0" class="lw-main-text">
                <span>Спален:</span>
                <span>{{ this.item.rooms.bedroom }}</span>
              </li>
              <li v-if="this.item.separate_wcs > 0 || this.item.combined_wcs > 0" class="lw-main-text">
                <span>с/у:</span>
                <span v-if="this.item.combined_wcs > 0">{{ this.item.combined_wcs }} с. </span><span v-if="this.item.separate_wcs">{{ this.item.separate_wcs }} р.</span>
              </li>
            </ul>
          </div>
          <div class="ps-0 col"  v-if="(this.$props.layout == 'adverts' || this.$props.layout == 'default')">
            <ul class="list-unstyled text-nowrap-mask me-2 lw-apartment-card__list lw-apartment-card__list--permissions">
              <li class="lw-main-text">
                <a href="javascript:{}" class="open_mebel_comment" 
                  data-bs-toggle="modal" 
                  data-bs-target="#updateServiceModal" 
                  data-bs-service="mebel" 
                  :data-bs-object-id="this.item.id">
                  <img v-if="this.item.mebel_list_id > 0 && this.item.mebel_list_id <= 4" class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/tick.svg')" alt="Кнопка" width="16" height="16"/>
                  <img v-else class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/cancel.svg')" alt="Кнопка" width="16" height="16">
                  <span class="lw-text-underlined">Мебель</span>
                </a>
              </li>
              <li class="lw-main-text">
                <a href="javascript:{}" class="open_no_child_comment" 
                  data-bs-toggle="modal" 
                  data-bs-target="#updateServiceModal" 
                  data-bs-service="no_child" 
                  :data-bs-object-id="this.item.id">
                  <img v-if="this.item.no_child > 0" class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/tick.svg')" alt="Кнопка" width="16" height="16"/>
                  <img v-else class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/cancel.svg')" alt="Кнопка" width="16" height="16">
                  <span class="lw-text-underlined">С детьми</span>
                </a>
              </li>
              <li class="lw-main-text">
                <a href="javascript:{}" class="open_no_pets_comment" 
                  data-bs-toggle="modal" 
                  data-bs-target="#updateServiceModal" 
                  data-bs-service="no_pets" 
                  :data-bs-object-id="this.item.id">
                  <img v-if="this.item.no_pets > 0" class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/tick.svg')" alt="Кнопка" width="16" height="16"/>
                  <img v-else class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/cancel.svg')" alt="Кнопка" width="16" height="16">
                  <span class="lw-text-underlined">С животными</span>
                </a>
              </li>
              <li class="lw-main-text">
                <a href="javascript:{}" class="open_parking_comment" 
                  data-bs-toggle="modal" 
                  data-bs-target="#updateServiceModal" 
                  data-bs-service="parking" 
                  :data-bs-object-id="this.item.id">
                  <img v-if="this.item.parking_type_id > 0" class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/tick.svg')" alt="Кнопка" width="16" height="16"/>
                  <img v-else class="lw-apartment-card__tag-desktop-icon" :src="require('@/assets/img/cancel.svg')" alt="Кнопка" width="16" height="16">
                  <span class="lw-text-underlined">Парковка</span>
                  <!--<span class="lw-text-underlined-no" v-if="!this.isEmpty(this.item.parking_name)"> {{ this.item.parking_name }}</span>-->
                </a>
              </li>
            </ul>
          </div>
          <div v-if="this.$props.layout !== 'search'" @click="сollapse" class="col-12 py-1" style="cursor: pointer;">
            <template v-if="this.item.is_collapse == 1">
              <span class="lw-base-text--small lw-text-green fs-4">Свернуть все настройки </span>
              <img :src="require('@/assets/icons/arrow_top_2.svg')">
            </template>
            <template v-else>
              <span class="lw-base-text--small lw-text-green fs-4">Развернуть все настройки  </span>
              <img :src="require('@/assets/icons/arrow_end.svg')">
            </template>
          </div>
          <template v-if="this.item.is_collapse == 1 || this.$props.layout == 'search'">
          <div v-if="(this.$props.layout == 'adverts' || this.$props.layout == 'default')" class="col-12">
            <ul class="lw-apartment-card__list lw-apartment-card__list--company list-unstyled text-nowrap-mask me-2 mb-0">
              <li>
                <div class="switch-container">
                  <input v-if="this.item.services.advertised.cian" @click="addRemoveObjectAdverts(this.item.id, 'cian', $event)" class="switch-checkbox" type="checkbox" :id="'checkdox_cian_feed_'+this.item.id"  data-budget="0" v-model="this.item.services.advertised.cian">
                  <input v-else data-bs-toggle="modal" data-bs-isswitcher="1" data-bs-target="#advertPromotionModal" data-bs-place="cian" :data-bs-object-id="this.item.id"  data-budget="0"  data-modal-id="lw-cian-modal"  class="switch-checkbox" type="checkbox" :id="'checkdox_cian_feed_'+this.item.id"  v-model="this.item.services.advertised.cian">
                  <label class="switch-label" :for="'checkdox_cian_feed_'+this.item.id"></label>
                </div>
                <button type="button" data-bs-toggle="modal" data-bs-target="#advertPromotionModal" data-bs-place="cian" :data-bs-object-id="this.item.id"  data-budget="0" class="lw-main-btn lw-apartment-card__company-btn" data-modal-id="lw-cian-modal" title="Необходимо наличие аккаутна на cian.ru">
                  <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/сian.svg')" alt="tick" width="80" height="32">
                </button>
                <div class="lw-main-btn">
                  <img :src="require('@/assets/img/share_1.svg')" alt="tick" width="20" height="20">
                </div>
                <InputSelect  :inputId="'cian_promo_'+this.item.id"
									 :inputName="'cian_promo_'+this.item.id"
									 :inputValue="this.item.promo.cian"
									 :inputOptions="this.list_cian_promo"
									 inputClass="rounded-1 p-2 custom-select-opener"
									 inputWrapClass="flex-column"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupPromo(value)" />
                <div class="lw-apartment-card__list-company-price">
                  <input type="text" @input="setupCianBet" class="change_cian_bet" :data-id="this.item.id" :id="'cian_bet_input_promo_'+this.item.id" placeholder="ставка" v-model="this.item.promo.cian_bet">
                  <span>₽</span>
                </div>
              </li>
              <li>
                <div class="switch-container">
                  <input v-if="this.item.services.advertised.avito" @click="addRemoveObjectAdverts(this.item.id, 'avito', $event)" class="switch-checkbox" type="checkbox" :id="'checkdox_avito_feed_'+this.item.id" :data-id="this.item.id" v-model="this.item.services.advertised.avito">
                  <input v-else data-bs-toggle="modal" data-bs-isswitcher="1" data-bs-target="#advertPromotionModal" data-bs-place="avito" :data-bs-object-id="this.item.id"  data-budget="0"  class="switch-checkbox" type="checkbox" :id="'checkdox_avito_feed_'+this.item.id"  v-model="this.item.services.advertised.avito">
                  <label class="switch-label" :for="'checkdox_avito_feed_'+this.item.id"></label>
                </div>
                <button type="button" class="lw-main-btn lw-apartment-card__company-btn" data-bs-toggle="modal" data-bs-target="#advertPromotionModal" data-bs-place="avito" :data-bs-object-id="this.item.id" data-budget="0" title="Необходимо наличие магазина Avito">
                  <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/avito.svg')" alt="tick" width="80" height="32">
                </button>
                <div class="lw-main-btn">
                  <img :src="require('@/assets/img/share_1.svg')" alt="tick" width="20" height="20">
                </div>
                <InputSelect  :inputId="'avito_promo_'+this.item.id"
									 :inputName="'avito_promo_'+this.item.id"
									 :inputValue="this.item.promo.avito"
									 :inputOptions="this.list_avito_promo"
									 inputClass="rounded-1 p-2 custom-select-opener"
									 inputWrapClass="flex-column"
									 inputUsePlaceholder="true"
									 @setValue="(value) => this.setupPromo(value)" />
                                                                        
                  </li>
                  <li>
                    <div class="switch-container">
                      <input v-if="this.item.services.advertised.yandex" @click="addRemoveObjectAdverts(this.item.id, 'yandex', $event)" class="switch-checkbox" type="checkbox" :id="'checkdox_yandex_feed_'+this.item.id" :data-id="this.item.id" v-model="this.item.services.advertised.yandex">
                      <input v-else data-bs-toggle="modal" data-bs-isswitcher="1" data-bs-target="#advertPromotionModal" data-bs-place="yandex" :data-bs-object-id="this.item.id"  data-budget="0"  class="switch-checkbox" type="checkbox" :id="'checkdox_yandex_feed_'+this.item.id"  v-model="this.item.services.advertised.yandex">
                  
                      <label class="switch-label" :for="'checkdox_yandex_feed_'+this.item.id"></label>
                    </div>
                                    
                    <button type="button" class="lw-main-btn lw-apartment-card__company-btn" data-bs-toggle="modal" data-bs-target="#advertPromotionModal" data-bs-place="yandex" :data-bs-object-id="this.item.id" data-budget="0">
                      <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/yandex.svg')" alt="tick" width="80" height="32">
                    </button>
                    <div class="lw-main-btn">
                      <img :src="require('@/assets/img/share_1.svg')" alt="tick" width="20" height="20">
                    </div>
                      <InputSelect  :inputId="'yandex_promo_select_'+this.item.id"
                        :inputName="'yandex_promo_select_'+this.item.id"
                        :inputValue="this.item.promo.yandex"
                        :inputOptions="this.list_yandex_promo"
                        inputClass="rounded-1 p-2 custom-select-opener"
                        inputWrapClass="flex-column"
                        inputUsePlaceholder="true"
                        @setValue="(value) => this.setupPromo(value)" />
                                   
                      </li>
                      <li>
                        <div class="switch-container">
                          
                          <input v-if="this.item.services.advertised.domclick" @click="addRemoveObjectAdverts(this.item.id, 'domclick', $event)" class="switch-checkbox" type="checkbox" :id="'checkdox_dom_feed_'+this.item.id" :data-id="this.item.id" v-model="this.item.services.advertised.domclick">
                          <input v-else data-bs-toggle="modal" data-bs-isswitcher="1" data-bs-target="#advertPromotionModal" data-bs-place="domclick" :data-bs-object-id="this.item.id"  data-budget="0"  class="switch-checkbox" type="checkbox" :id="'checkdox_dom_feed_'+this.item.id"  v-model="this.item.services.advertised.domclick">
                  
                          <label class="switch-label" :for="'checkdox_dom_feed_'+this.item.id"></label>
                        </div>
                        <button type="button" class="lw-main-btn lw-apartment-card__company-btn" data-bs-toggle="modal" data-bs-target="#advertPromotionModal" data-bs-place="domclick" :data-bs-object-id="this.item.id" data-budget="0" title="Размещение на Домклик">
                          <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/domclick.svg')" alt="tick" width="80" height="32">
                        </button>
                        <div class="lw-main-btn">
                          <img :src="require('@/assets/img/share_1.svg')" alt="tick" width="20" height="20">
                        </div>
                        <InputSelect  :inputId="'dom_promo_select_'+this.item.id"
                          :inputName="'dom_promo_select_'+this.item.id"
                          :inputValue="this.item.promo.domclick"
                          :inputOptions="this.list_domclick_promo"
                          inputClass="rounded-1 p-2 custom-select-opener"
                          inputWrapClass="flex-column"
                          inputUsePlaceholder="true"
                          @setValue="(value) => this.setupPromo(value)" />
                  </li>
                  <li>
                    <div class="switch-container">
                      
                      <input v-if="this.item.services.advertised.free" @click="addRemoveObjectAdverts(this.item.id, 'free', $event)" class="switch-checkbox" type="checkbox" :id="'checkdox_free_feed_'+this.item.id"  data-budget="0" v-model="this.item.services.advertised.free">
                      <input v-else data-bs-toggle="modal" data-bs-isswitcher="1" data-bs-target="#advertDaysModal" :data-bs-object-id="this.item.id"  data-budget="0" data-bs-service="free"  data-modal-id="lw-cian-modal"  class="switch-checkbox" type="checkbox" :id="'checkdox_free_feed_'+this.item.id"  v-model="this.item.services.advertised.free">
                 
                      <label class="switch-label" :for="'checkdox_free_feed_'+this.item.id"></label>
                    </div>
                    <button type="button" class="lw-main-btn lw-apartment-card__company-btn" data-bs-toggle="modal" data-bs-service="free" data-bs-target="#advertDaysModal" :data-bs-object-id="this.item.id"  data-budget="0"  data-modal-id="lw-cian-modal">
                      <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/site.svg')" alt="tick" width="80" height="32">
                    </button>
                  </li>    
            </ul>
          </div>
        </template>
          
          <!--<div v-if="this.item.areas || this.item.floors" class="ps-0 col">
            <ul class="list-unstyled mb-0">
              <li v-if="!this.isEmpty(this.item.areas.full)" class="py-1 text-black" v-text="this.item.areas.full + ' кв.м'"/>
              <li v-if="!this.isEmpty(this.item.areas.live)" class="py-1 text-dark-gray"
                  v-text="'Жилая: ' + this.item.areas.live + ' кв.м'"/>
              <li v-if="!this.isEmpty(this.item.areas.kitchen)" class="py-1 text-dark-gray"
                  v-text="'Кухни: ' + this.item.areas.kitchen + ' кв.м'"/>
              						<li v-if="!this.isEmpty(this.item.floors.num) || !this.isEmpty(this.item.floors.max)" class="py-1 text-black"
                              v-text="this.item.floors.num + ' этаж' + (!this.isEmpty(this.item.floors.max) ? ' из ' + this.item.floors.max : '')"/>
              <li v-if="!this.isEmpty(this.floors)" class="py-1 text-black"
                  v-text="this.floors"/>
            </ul>
          </div>
          <div v-if="!this.isEmpty(this.item.features)" class="col-12">
            <ul class="list-unstyled d-inline-flex mb-0">
              <li v-if="this.item.features.fridge" class="py-1 ms-0 me-2">
                <img :src="require('@/assets/icons/fridge-active-icon.svg')" class="m-auto" alt="Холодильник" width="28" height="38">
              </li>
              <li v-if="this.item.features.washer" class="py-1 ms-0 me-2">
                <img :src="require('@/assets/icons/washer-active-icon.svg')" class="m-auto" alt="Стиральная машина" width="28" height="38">
              </li>
              <li v-if="this.item.features.tv" class="py-1 ms-0 me-2">
                <img :src="require('@/assets/icons/tv-active-icon.svg')" class="m-auto" alt="Телевизор" width="28" height="38">
              </li>
              <li v-if="this.item.features.furniture" class="py-1 ms-0 me-2">
                <img :src="require('@/assets/icons/furniture-active-icon.svg')" class="m-auto" alt="Мебель" width="28" height="38">
              </li>
            </ul>
          </div>-->
          
          <div v-if="this.item.address || this.item.source" class="col-12">
            <ul class="list-unstyled mb-0">
              <li v-if="this.item.address" class="py-1 text-black" v-text="this.item.address"></li>
              <li v-if="this.item.source" class="py-1 text-dark-gray">
                Ссылка на источник: <a :href="this.item.source.url" class="link" target="_blank" v-text="this.item.source.name"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.item.is_collapse == 1 || this.$props.layout == 'search'">
    <div v-show="this.onmore_tools_buttons" class="card-body position-relative overflow-visible">
      <div class="d-flex flex-wrap align-items-end mb-2">
        <a v-if="this.user_can.send_presentation" href="javascript:{}"
           class="btn btn-services"
           :class="(this.isCheckedForPDF(this.item.id)) ? 'btn-dark-gray' : 'btn-danger'"
           title="Отправить в презентацию"
           @click="(this.isCheckedForPDF(this.item.id)) ? this.$store.commit('removeObjectFromPDF', this.item.id) : this.$store.commit('addObjectToPDF', this.item.id)">
          <img :src="require('@/assets/icons/pdf-file-white.svg')" alt="PDF" width="24" height="24">
        </a>
        <button v-if="this.user_can.publish && this.user_can.vk_post"
                class="btn btn-vkontakte btn-services"
                title="Опубликовать в вКонтакте"
                data-bs-toggle="modal"
                :data-bs-target="'#submitToVKModal'"
                :data-bs-object-id="this.item.id">
          <img :src="require('@/assets/icons/vk.svg')" alt="ВКонтакте" width="24" height="24">
        </button>
        <!--<a v-if="this.user_can.download_photo"
           href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Скачать фото" @click="this.getObjectPhotos()">
          <img :src="require('@/assets/icons/download.svg')" alt="Скачать" width="24" height="24">
        </a>-->
        <a v-if="this.user_can.tasks"
           href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Задачи по объекту"
           data-bs-toggle="modal"
           data-bs-target="#historyTasksModal"
           data-bs-section="objects"
           :data-bs-object-id="this.item.id"
           :data-bs-object-status-id="this.item.status_object_id"
           >
          <img :src="require('@/assets/icons/checklist.svg')" alt="Задачи" width="24" height="24">
        </a>
        <a v-if="this.user_can.autosearch"
           href="javascript:{}"
           role="button"
           class="btn btn-light-gray border btn-services"
           :class="{ 'me-3': this.item.autosearch, 'disabled': (this.is_reverse_process || this.isEmpty(this.item.autosearch)) }"
           title="Заявки по автопоиску"
           data-bs-toggle="modal"
           :data-bs-target="'#autoSearchReqModal'"
           :data-bs-object-id="this.item.id"
           :disabled="this.is_reverse_process">
          <img :src="require('@/assets/icons/zoom-in.svg')" alt="Автопоиск" width="24" height="24">
          <span v-if="this.is_reverse_process"
                class="d-inline-flex align-self-center badge badge-loader rounded-circle"
                v-text="' '" />
          <span v-else-if="!this.is_reverse_process && !this.isEmpty(this.item.autosearch)"
                class="d-inline-flex align-self-center badge bg-success fs-5"
                :class="(this.item.autosearch.count > 9) ? 'rounded-3' : 'rounded-circle'"
                v-text="this.item.autosearch.count" />
        </a>
        <a v-if="this.user_can.add" href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Добавить в мои объекты"
           @click="this.copyToMyObjects()">
          <img :src="require('@/assets/icons/stay-home.svg')" alt="В мои объекты" width="24" height="24">
        </a>
        <!--<a v-if="this.user_can.notes"
           href="javascript:{}"
           class="btn btn-services"
           :class="(this.item.notes_count > 0) ? 'icon-color-warning btn-outline-warning' : 'border btn-light-gray'"
           title="Примечания по объекту"
           data-bs-toggle="modal"
           data-bs-target="#historyTasksModal"
           data-bs-section="objects"
           :data-bs-object-id="this.item.id"
           data-bs-action="notes">
          <img :src="require('@/assets/icons/planning.svg')" alt="Примечания" width="24" height="24">
          <span v-if="this.item.notes_count"
                class="d-inline-flex align-self-center badge bg-warning fs-5"
                :class="(this.item.notes_count > 9) ? 'rounded-3' : 'rounded-circle'"
                v-text="this.item.notes_count" />
        </a>-->
        <!--				<a v-if="(!this.item.is_archived && this.user_can.archive)" href="javascript:{}"-->
        <!--<a v-if="(!this.item.is_archived && (this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')))" href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Добавить в архив"
           data-bs-toggle="modal"
           data-bs-target="#archiveObjectModal"
           :data-bs-object-id="this.item.id">
          <img :src="require('@/assets/icons/folder.svg')" alt="Архивировать" width="24" height="24">
        </a>-->
        <!--				<a v-else-if="this.user_can.archive" href="javascript:{}"-->
        <!--<a v-else-if="this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" href="javascript:{}"
           class="btn btn-light-gray border btn-services active"
           title="Вернуть из архива"
           @click="removeObjectArchive(this.item.id, $event)">
          <img :src="require('@/assets/icons/back-icon.svg')" alt="Вернуть..." width="24" height="24">
        </a>-->
        <!--				<a v-if="this.item.is_transfered && this.user_can.transfer" href="javascript:{}"-->
        <a v-if="this.item.is_transfered && (this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts'))" href="javascript:{}"
           class="btn btn-light-gray border btn-services active"
           title="Отменить передачу"
           data-bs-toggle="modal"
           :data-bs-object-id="this.item.id"
           data-bs-target="#cancelDelegateObjectModal">
          <img :src="require('@/assets/icons/transfer.svg')" alt="Передача объекта..." width="24" height="24">
        </a>
        <!--				<a v-else-if="this.user_can.transfer" href="javascript:{}"-->
        <a v-else-if="this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts')" href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Передать сотруднику"
           data-bs-toggle="modal"
           :data-bs-object-id="this.item.id"
           data-bs-target="#delegateObjectModal">
          <img :src="require('@/assets/icons/transfer.svg')" alt="Передать" width="24" height="24">
        </a>
        <a v-if="this.item.requisitions_count && this.item.this_user_can_edit"
           href="javascript:{}"
           class="btn btn-light-gray border btn-services"
           title="Заявки по объекту"
           data-bs-toggle="modal"
           data-bs-target="#requisitionsModal"
           data-bs-section="objects"
           :data-bs-object-id="this.item.id"
           style="min-width: 45px"
           v-text="this.item.requisitions_count" />
        <a v-if="this.$props.layout !== 'search' && !this.egrn_reports.count && this.user_can.egrn_reports" href="javascript:{}"
           class="btn btn-outline-warning btn-services px-3"
           data-bs-toggle="modal"
           data-bs-target="#orderEgrnModal"
           :data-bs-object-id="this.item.id">
          Выписка
        </a>
        <a v-else-if="this.$props.layout !== 'search' && this.egrn_reports.count && this.user_can.egrn_reports" href="javascript:{}"
           class="btn btn-outline-success btn-services px-3 mt-2 me-4"
           :class="(this.egrn_reports.done > 0) ? 'btn-outline-success' : 'btn-outline-warning'"
           :title="(this.egrn_reports.done > 0) ? this.declOfNum(this.egrn_reports.done, ['выписка', 'выписки', 'выпесок'], true) + ' из '+this.declOfNum(this.egrn_reports.count, ['выписки', 'выписок', 'выписок'], true) + ' готовы' : 'Ожидают обработки '+this.declOfNum(this.egrn_reports.count, ['выписка', 'выписки', 'выпесок'], true)"
           data-bs-toggle="modal"
           :data-bs-target="(this.egrn_reports.done > 0) ? '#listEgrnModal' : '#orderEgrnModal'"
           :data-bs-object-id="this.item.id">
          Выписка
          <span v-if="this.egrn_reports.done" class="d-inline-flex align-self-center badge split bg-success fs-5"
                :class="(this.egrn_reports.done > 9) ? 'rounded-3' : 'rounded-circle'"
                v-text="this.egrn_reports.done" />
          <span v-if="this.egrn_reports.done" class="d-inline-flex align-self-center badge split bg-warning fs-5"
                :class="(this.egrn_reports.done > 9) ? 'rounded-3' : 'rounded-circle'"
                v-text="this.egrn_reports.count" />
          <span v-else class="d-inline-flex align-self-center badge bg-warning fs-5"
                :class="(this.egrn_reports.count > 9) ? 'rounded-3' : 'rounded-circle'"
                v-text="this.egrn_reports.count" />
        </a>
        <a v-else-if="this.$props.layout !== 'search' && this.user_can.egrn_reports" href="javascript:{}"
           class="btn btn-outline-warning btn-services px-3"
           data-bs-toggle="modal"
           data-bs-target="#tarifsEgrnModal">
          Выписка
        </a>
        <!--				<a v-if="this.user_can.adverts && !this.user_can.add_to_moderation" href="javascript:{}"-->
       <!--<a v-if="!this.user_can.add_to_moderation && (this.item.this_user_can_edit || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts'))" href="javascript:{}"
           class="btn btn-outline-success btn-services px-3"
           >
          Реклама
          <span v-if="this.user_can.moderate_adverts && !this.item.services.is_moderated && !this.isEmpty(this.item.moderation)"
                class="badge bg-danger icon-color-white px-0 fs-5 rounded-circle"
                style="width: 26px; height: 26px;">
						<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex m-auto" width="14" height="14" />
					</span>
        </a> -->
        <div class="d-none">
          <div :id="'orderAdverts' + this.item.id" class="col">
            <div v-if="!this.isEmpty(this.moderation_errors)" class="mt-3">
              <div v-for="(error, index) of this.moderation_errors"
                   :key="index" class="alert alert-danger rounded-3 mb-3">
                {{ error }}
              </div>
            </div>
            <div class="row row-cols-2 pe-3">
              <div v-if="this.user_can.advertise.zipal" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.zipal)" href="javascript:{}"
                   :class="['btn btn-ads', {
									   'active': (this.item.services.advertised.zipal && !this.item.services.errors.zipal),
									   'border-danger': (this.item.services.errors.zipal),
								   }]"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'zipal', $event)"
                   title="С отчётами">
                  С отчётами
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', {
									   'active': (this.item.services.advertised.zipal && !this.zipal_error),
									   'border-danger': this.zipal_error,
								   }]"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="zipal"
                   :data-bs-object-id="this.item.id"
                   title="С отчётами">
                  С отчётами
                </a>
                <button v-show="this.zipal_error" class="btn btn-help px-1"
                        data-bs-toggle="popover"
                        data-bs-placement="top"
                        :data-bs-target="'#advertsError' + this.item.id"
                        data-bs-container="body"
                        data-bs-class="popover-info start-0 end-0 border-danger text-danger shadow">
                  <img :src="require('@/assets/icons/attention.svg')" alt="Ошибка" height="26" />
                </button>
              </div>
              <div v-if="this.user_can.advertise.free" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.free)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.free) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'free', $event)"
                   title="Бесплатные площадки">
                  Бесплатные
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.free) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="free"
                   :data-bs-object-id="this.item.id"
                   title="Бесплатные площадки">
                  Бесплатные
                </a>
                <button class="btn btn-help px-1"
                        data-bs-toggle="popover"
                        data-bs-placement="bottom"
                        :data-bs-target="'#helpFreeAdverts' + this.item.id"
                        data-bs-container="body"
                        data-bs-class="popover-info start-0 end-0 shadow">
                  <img :src="require('@/assets/icons/question.svg')" alt="Помощь" height="26" />
                </button>
              </div>
              <div v-if="this.user_can.advertise.avito" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.avito)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.avito) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'avito', $event)"
                   title="Размещение на Avito">
                  <img :src="require('@/assets/svg/avito-logo.svg')" alt="Avito" height="36" />
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.avito) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="avito"
                   :data-bs-object-id="this.item.id"
                   title="Размещение на Avito">
                  <img :src="require('@/assets/svg/avito-logo.svg')" alt="Avito" height="36" />
                </a>
              </div>
              <div v-if="this.user_can.advertise.cian" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.cian)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.cian) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'cian', $event)"
                   title="Размещение на ЦИАН">
                  <img :src="require('@/assets/svg/cian-logo.svg')" alt="ЦИАН" height="36" />
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.cian) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="cian"
                   :data-bs-object-id="this.item.id"
                   title="Размещение на ЦИАН">
                  <img :src="require('@/assets/svg/cian-logo.svg')" alt="ЦИАН" height="36" />
                </a>
              </div>
              <div v-if="this.user_can.advertise.zipal" class="d-flex col pb-2">
                <a href="javascript:{}"
                   :class="['btn btn-ads', {
									   'active': (this.item.services.advertised.zipal && !this.packages_error),
									   'border-danger': this.packages_error,
								   }]"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="packages"
                   :data-bs-object-id="this.item.id"
                   title="Пакетное размещение">
                  Пакеты
                </a>
                <button v-show="this.packages_error" class="btn btn-help px-1"
                        data-bs-toggle="popover"
                        data-bs-placement="top"
                        :data-bs-target="'#advertsError' + this.item.id"
                        data-bs-container="body"
                        data-bs-class="popover-info start-0 end-0 border-danger text-danger shadow">
                  <img :src="require('@/assets/icons/attention.svg')" alt="Ошибка" height="26" />
                </button>
              </div>
              <div v-if="this.user_can.advertise.domclick" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.domclick)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.domclick) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'domclick', $event)"
                   title="Размещение на ДомКлик">
                  <img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="36" />
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.domclick) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="domclick"
                   :data-bs-object-id="this.item.id"
                   title="Размещение на ДомКлик">
                  <img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" height="36" />
                </a>
              </div>
              <!-- div v-if="this.user_can.advertise.emls" class="d-flex col">
                <a href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.emls) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'emls', $event)"
                   title="Размещение на EMLS">
                  <img :src="require('@/assets/svg/emls-logo.svg')" alt="EMLS" height="36" />
                </a>
              </div -->
              <div v-if="this.user_can.advertise.jcat" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.jcat)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.jcat) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'jcat', $event)"
                   title="Размещение на JCat">
                  <img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="36" />
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.jcat) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="jcat"
                   :data-bs-object-id="this.item.id"
                   title="Размещение на JCat">
                  <img :src="require('@/assets/svg/jcat-logo.svg')" alt="JCat" height="36" />
                </a>
              </div>
              <div v-if="this.user_can.advertise.yandex" class="d-flex col pb-2">
                <a v-if="(this.item.services.advertised.yandex)" href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.yandex) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   @click="addRemoveObjectAdverts(this.item.id, 'yandex', $event)"
                   title="Размещение на Яндекс.Недвижимость">
                  <img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс.Недвижимость" height="36" />
                </a>
                <a v-else href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.advertised.yandex) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertDaysModal"
                   data-bs-service="yandex"
                   :data-bs-object-id="this.item.id"
                   title="Размещение на Яндекс.Недвижимость">
                  <img :src="require('@/assets/svg/yandex-logo.svg')" alt="Яндекс.Недвижимость" height="36" />
                </a>
                <button class="btn btn-help px-1"
                        data-bs-toggle="popover"
                        data-bs-placement="top"
                        :data-bs-target="'#helpYandexAdverts' + this.item.id"
                        data-bs-container="body"
                        data-bs-class="popover-info start-0 end-0 shadow">
                  <img :src="require('@/assets/icons/question.svg')" alt="Помощь" height="26" />
                </button>
              </div>
              <div v-if="this.user_can.advertise.promotion && this.$props.layout == 'adverts'" class="d-flex col pb-2">
                <a href="javascript:{}"
                   :class="['btn btn-ads', (this.item.services.is_promotion) ? 'active' : '']"
                   :disabled="this.is_updating_process"
                   data-bs-toggle="modal"
                   data-bs-target="#advertPromotionModal"
                   :data-bs-object-id="this.item.id"
                   title="Продвижение">
                  Продвижение
                </a>
              </div>
            </div>
            <div v-if="this.user_can.moderate_adverts && !this.isEmpty(this.item.moderation)" class="d-flex pt-2 col-12">
              <div v-if="!this.item.services.is_moderated" class="d-flex col-12">
                <button type="button"
                        class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
                        :disabled="this.is_updating_process"
                        @click="this.setConfirmModeration()">
                  Подтвердить
                </button>
              </div>
              <div v-else-if="!this.need_approve_moderation && this.item.services.is_moderated" class="d-flex col-12">
								<span class="alert alert-info col-12 mb-0 rounded-3 px-20 text-center">
									<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex m-auto me-2" width="14" height="14" />
									Модерация подтверждена
								</span>
              </div>
            </div>
            <div v-if="(!this.user_can.moderate_adverts && this.user_can.add_to_moderation) && !this.item.services.is_moderated && this.moderation_status == 'pending'" class="d-flex pt-2 col-12">
              <div class="row col">
                <div class="d-flex col-12">
									<span class="alert alert-info col-12 mb-0 rounded-3 px-20 text-center">
										<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex m-auto me-2" width="14" height="14" />
										Отправлен на модерацию
									</span>
                </div>
              </div>
            </div>
            <div v-else-if="(!this.user_can.moderate_adverts && this.user_can.add_to_moderation) && !this.item.services.is_moderated" class="d-flex pt-2 col-12">
              <div class="row">

                <SelectBox inputId="adverts_moderators"
                           inputName="adverts_moderators"
                           ref="adverts_moderators"
                           inputLabel="Ответственные:"
                           :inputOptions="this.moderators_list"
                           :inputValue="(!this.isEmpty(this.adverts_moderators)) ? this.adverts_moderators : []"
                           inputLabelClass="text-font-secondary mb-3"
                           inputClass="rounded-3 p-2"
                           inputWrapClass="mb-20"
                           inputSearch="true"
                           inputMultiple="true"
                           @setValue="(value) => (!this.isEmpty(value)) ? this.adverts_moderators = value : this.adverts_moderators = []" />

                <TextArea inputId="adverts_comment"
                          inputLabel="Комментарий:"
                          inputName="adverts_comment"
                          ref="adverts_comment"
                          inputLabelClass="text-font-secondary mb-3"
                          :inputValue="(!this.isEmpty(this.adverts_comment)) ? this.adverts_comment : null"
                          inputClass="rounded-3 p-2"
                          inputWrapClass="mb-20"
                          @setValue="(value) => this.adverts_comment = value" />

                <div class="mb-0">
                  <button type="button"
                          class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
                          :disabled="this.isEmpty(this.adverts_moderators) || !this.is_adverts_setup || this.is_updating_process"
                          @click="this.addObjectToModeration()">
                    Отправить на модерацию
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none">
            <div :id="'helpFreeAdverts' + this.item.id">
              <ul class="list-unstyled p-2">
                <li>Egent.ru</li>
                <li>Fotomakler.ru</li>
                <li>Chastnik.ru</li>
                <li>QP.ru</li>
                <li>Realtymag.ru</li>
                <li>Lenobl-dom.ru</li>
                <li>Rentzilla.ru</li>
                <li>RegionalRealty.ru</li>
                <li>Kvmeter.ru</li>
                <li>Mesto.ru</li>
                <li>Rucountry.ru</li>
                <li>Bestru.ru</li>
                <li>Gde.ru</li>
                <li>G-N.ru</li>
                <li>Nedvrf.ru</li>
                <li>World-Real-Estate.ru</li>
                <li>Квартиры-домики.рф</li>
                <li>Realtyport.su</li>
                <li>RealtyPult.ru</li>
                <li>Restate.ru</li>
              </ul>
            </div>
            <div :id="'helpYandexAdverts' + this.item.id">
              <p class="me-3">Для подключения ДомКлик, Avito, Domofond, EMLS, ЦИАН и BN свяжитесь с нами по телефону:
                <br/><a href="tel:88129815617">8 (812) 981-56-17</a></p>
            </div>
            <div :id="'advertsError' + this.item.id">
              <p class="me-2 mb-0">Внимание! Есть ошибки размещения.</p>
            </div>
          </div>
        </div>
      </div>

      <Switcher v-show="this.user_can.add_to_adverts && this.user_can.add_to_moderation"
                inputLabel="В рекламу (модерация)"
                inputClass="ps-0"
                :inputValue="this.item.use_in_advert"
                ref="orderAdvertsPopover"
                data-bs-toggle="popover"
                :data-bs-target="(this.need_show_moderation == 1) ? '#orderAdverts' + this.item.id : null"
                data-bs-placement="top"
                data-bs-only-one="true"
                data-bs-class="popover-info start-0 end-0 shadow"
                data-bs-body-class="pt-2"
                @setValue="(value) => this.getModerators(this.item.id, value)" />

      <!--			<Switcher v-show="this.user_can.add_to_adverts && !this.user_can.add_to_moderation"-->
      <Switcher v-show="this.$props.layout !== 'search' && !this.user_can.add_to_moderation && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin || this.$store.getters.userInfo.permissions.menu_all_objects_edit == 1 || this.$store.getters.userInfo.id == this.item.add_user_id || (this.$store.getters.userInfo.permissions.menu_ads == 1 && this.$route.name == 'adverts'))"
                inputLabel="В продвижение"
                inputClass="ps-0"
                :inputValue="this.item.use_in_advert"
                @setValue="(value) => this.sendObjectToAdverts(this.item.id, value, $event)" 
                />
      <ul v-if="this.item.this_user_can_edit && !this.isEmpty(this.item.owners)" class="list-unstyled mt-3 mb-0">
        <li v-for="(owner, index) of this.item.owners"
            :key="index">
          <router-link :to="'/edit-client?id='+owner.client_id"
                       class="btn py-0 btn-client d-inline-flex flex-fill"
                       :data-client-id="owner.client_id">
						<span class="icon">
							<img :src="require('@/assets/icons/user-card-icon.svg')" alt="Клиент" width="12" height="12">
						</span>
            <span v-if="!this.isEmpty(owner.name)">{{ owner.name }}</span>
            <!--						<span v-if="!this.isEmpty(owner.phone)">, {{ owner.phone }}</span>
                        <span v-if="!this.isEmpty(owner.email)">&nbsp;({{ owner.email }})</span>-->
          </router-link>
          <a v-if="(this.inArray(this.$props.layout, ['default', 'adverts']) || this.item.user_id == this.$store.getters.userInfo.id)"
             href="javascript:{}"
             class="btn btn-sm btn-light-gray ms-2 border rounded-1"
             title="Задачи по клиенту"
             data-bs-toggle="modal"
             data-bs-target="#historyTasksModal"
             data-bs-section="clients"
             :data-bs-client-id="owner.client_id">
            <img :src="require('@/assets/icons/checklist.svg')" alt="Задачи" width="20" height="20" style="margin-top: -2px">
          </a>
        </li>
      </ul>

      <ul v-else-if="!this.isEmpty(this.item.owners)" class="list-unstyled mt-3 mb-0">
        <li v-for="(owner, index) of this.item.owners"
            :key="index">
          <span v-if="!this.isEmpty(owner.name)">{{ owner.name }}</span>
        </li>
      </ul>



    </div>
  </div>
    <div class="card-body position-relative overflow-visible">
      <div class="row">
        <div class="col-6">
          <div class="d-flex justify-content-center flex-column">
            <div class="d-inline-flex flex-fill align-items-center ext-black fs-2">
              <a href="javascript:{}"
                 v-if="!this.isEmpty(this.item.prices.history) || (this.item.prices.prep || this.item.prices.start || this.item.prices.fact)"
                 class="btn btn-price-history me-2"
                 data-bs-toggle="popover"
                 :data-bs-target="'#priceHistory' + this.item.id"
                 data-bs-offset="30,10"
                 data-bs-container=".object-item .card-body"
                 data-bs-class="popover-info start-0 end-0 shadow"
                 data-bs-body-class="w-100 pt-2 pe-3">
                <img :src="require('@/assets/icons/profit.svg')" alt="Статистика" width="12" height="12">
              </a>
              {{ formatPrice(this.item.prices.amount, this.item.prices.currency, true) }}
            </div>
            
            <div v-if="this.item.stoim_kvm > 0" class="flex-fill text-dark-gray fs-5">
              {{ 
                formatPrice(this.item.stoim_kvm, this.item.prices.currency)
              }}
              (м<sup>2</sup>)
            </div>
            <div v-if="this.item.prices.commission > 0" class="flex-fill text-dark-gray fs-5">Комиссия
              {{ this.item.prices.commission + '%' }} - {{
                formatPrice(Math.round((this.item.prices.amount * this.item.prices.commission) / 100, 2), this.item.prices.currency)
              }}
            </div>
            
          </div>
          <div v-if="!this.isEmpty(this.item.prices.history) || (this.item.prices.prep || this.item.prices.start || this.item.prices.fact)" class="d-none">
            <div :id="'priceHistory' + this.item.id">
              <div v-if="!this.isEmpty(this.item.prices.history)">
                <b class="fs-3">Статистика изменения цены</b>
                <table class="w-100 mt-2 mb-3">
                  <thead>
                  <tr>
                    <th class="fst-normal text-dark-gray">Дата:</th>
                    <th class="fst-normal text-dark-gray">Цена:</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in this.item.prices.history"
                      :key="this.item.id + '_price_' + index">
                    <td v-text="row.date" />
                    <td v-text="formatPrice(row.price, this.item.prices.currency, true) + ((row.commission) ? ' + ' + formatPrice(row.commission, this.item.prices.currency, true) : '')" />
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="(this.item.prices.prep || this.item.prices.start || this.item.prices.fact)">
                <b class="fs-3">Данные оценки</b>
                <table class="w-100 mt-2">
                  <tbody>
                  <tr>
                    <td v-text="'Предварительная цена:'" />
                    <td v-text="formatPrice(this.item.prices.prep, this.item.prices.currency, true)" />
                  </tr>
                  <tr>
                    <td v-text="'Начальная цена:'" />
                    <td v-text="formatPrice(this.item.prices.start, this.item.prices.currency, true)" />
                  </tr>
                  <tr>
                    <td v-text="'Фактическая цена:'" />
                    <td v-text="formatPrice(this.item.prices.fact, this.item.prices.currency, true)" />
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="ps-0 col-6">
          <div v-if="!this.isEmpty(this.item.author.fio)" class="text-dark-gray fs-4 text-nowrap-mask pb-1" v-text="this.item.author.fio" />
          <div v-else-if="!this.isEmpty(this.item.agency)" class="text-dark-gray fs-4 text-nowrap-mask pb-1" v-text="this.item.agency.name" />
          <ul v-if="!this.isEmpty(this.item.author) || !this.isEmpty(this.item.agency)" class="list-unstyled mb-auto">
            <li v-for="(phone, index) of this.item.author.phones"
                :key="this.item.id + '_author_phone_' + index">
              <a v-if="phone" :href="this.formatPhone(phone, true)" class="text-black" v-text="this.formatPhone(phone)" />
            </li>
            <li v-for="(phone, index) of this.item.agency.phones"
                :key="this.item.id + '_agency_phone_' + index">
              <a v-if="phone" :href="this.formatPhone(phone, true)" class="text-black" v-text="this.formatPhone(phone)" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!this.isEmpty(this.item.description) || (!this.isEmpty(this.item.geo.lat) && !this.isEmpty(this.item.geo.lng)) || !this.isEmpty(this.item.date_call)"
         class="card-body position-relative overflow-visible">
      <a v-if="this.item.geo.lat && this.item.geo.lng"
         href="javascript:{}"
         class="card-link me-2 text-dark-gray border-0"
         data-bs-toggle="modal"
         data-bs-target="#objectOnMapModal"
         :data-bs-lat="this.item.geo.lat"
         :data-bs-lng="this.item.geo.lng">На карте</a>
      <a v-if="!this.isEmpty(this.item.description)"
         href="javascript:{}"
         class="card-link me-2 text-dark-gray border-0"
         data-bs-toggle="popover"
         :data-bs-target="'#objectDescription' + this.item.id"
         data-bs-placement="top"
         data-bs-container=".object-item .card-body"
         data-bs-class="start-0 end-0 p-4 shadow">Описание
        </a>

      <p class="mt-2">Создан: {{ new Date(this.item.created_at).toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</p>
        
        <div v-if="!this.isEmpty(this.item.date_call)" class="d-flex flex-shrink-0 ms-auto me-0" style="float:right;">
          <a href="javascript:{}" class="btn d-flex align-items-center p-0" 
          @click="this.$emit('openTask', this.item.id)" 
          >
           <span class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1" style="width: 1.875rem; height: 1.875rem;">
            <img :src="require('@/assets/icons/phone-icon.svg')" class="d-block" alt="Дата прозвона" width="10" height="10">
          </span>
          <span class="text-dark fs-5 ps-2" :class="this.item.class_date_call">Дата прозвона: {{ this.item.date_call }}</span>
        </a>
      </div>
    </div>
    
    <div v-if="!this.isEmpty(this.item.description)" class="d-none">
      <div :id="'objectDescription' + this.item.id">
        <Editor 
                    inputLabel="Описание:"
                    inputName="description"
                    ref="description"
                    inputClass="rounded-1 mb-10 d-none"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.description"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Во избежании блокировки в описании не следует указывать телефон, сайт, e-mail или другие контакты.'"
                    @setValue="(value) => this.setAndValidate('description', value)" />
      </div>
    </div>

    <ConfirmModal :id="this.item.id"
                  :title="this.confirmModalState.title"
                  :text="this.confirmModalState.text"
                  :recipient="this.confirmModalState.recipient"
                  :fromSection="this.confirmModalState.fromSection"
                  :btnText="this.confirmModalState.btnText"
                  @confirmed="deleteObject()" />

    <ResultsModal :id="this.item.id"
                  :state="this.resultsModalState.state"
                  :title="this.resultsModalState.title"
                  :message="this.resultsModalState.message"
                  :successAction="() => {
					  	if (this.inArray(this.$props.layout, ['default', 'adverts'])) {
							this.$emit('updateObjectsList', this);
					  	}
					  }" />




          

  </div>
</template>

<style lang="scss">
@import '@/assets/styles/variables';

.lw-main-text {
  font-weight: 600;
  font-size: clamp(12px, 1.3vw, 14px);
}

.lw-main-text--small {
  font-size: 12px;
  font-weight: 400;
}

.lw-main-btn .lw-apartment-card__list-company-image {
  max-width: 64px;
  max-height: 16px;
}


  .switch-container {
  display: inline-block;
  position: relative;
}

.switch-container input[type="checkbox"] {
  display: none;
}

.switch-container label {
  display: block;
  width: 36px; /* Ширина ползунка */
  height: 22px; /* Высота ползунка */
  background-color: #ccc; /* Цвет фона ползунка (неактивный) */
  border-radius: 20px; /* Радиус скругления ползунка */
  position: relative;
  cursor: pointer;
}

.switch-container input[type="checkbox"]:checked + label {
  background-color: #4caf50; /* Цвет фона ползунка (активный) */
}

.switch-container label:before {
  content: "";
  position: absolute;
  width: 16px; /* Ширина переключателя */
  height: 16px; /* Высота переключателя */
  background-color: #fff; /* Цвет переключателя */
  border-radius: 50%; /* Радиус скругления переключателя */
  top: 50%; /* Положение по верхнему краю */
  transform: translateY(-50%);
  left: 2px; /* Положение по левому краю */
  transition: 0.3s; /* Плавное изменение состояния */
}

.switch-container input[type="checkbox"]:checked + label:before {
  left: calc(100% - 18px);
  /* transform: translateY(-50%) Сдвигаем переключатель на активное состояние */
}

.lw-main-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: fit-content;
  min-width: 48px;
  min-height: 28px;
  font-weight: 400;
  font-size: 12px;
  background: linear-gradient(180deg, #fefefe 0%, #f6f6f6 100%);
  border: 1px solid #dedede;
  border-radius: 3px;
  color: #757575;
}

.lw-main-btn--no-border {
  background: none;
  border: none;
  padding: 0;
}

.lw-main-btn--icon {
  position: relative;
  padding-left: 18px;
}

.lw-main-btn--icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 16px;
  max-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.lw-main-btn img {
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
}

.lw-apartment-card__pdf-btn {
  background: linear-gradient(180deg, #ff5761 0%, #e52a35 100%);
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.lw-apartment-card__vk-btn {
  background: linear-gradient(180deg, #6b86a7 0%, #557197 100%);
}

.lw-apartment-card__extract-btn {
  border: 1px solid #0374ff;
  color: #0374ff;
}

.lw-apartment-card__promotion-btn {
  border: 1px solid #4caf50;
  color: #4caf50;
}

.lw-apartment-card__document-btn img {
  max-height: 16px;
}

.lw-apartment-card__list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
  margin: 0;
}

.lw-apartment-card__list.lw-apartment-card__list--chars {
  margin-right: 20px; /*clamp(50px, 4vw, 80px);*/
}


.lw-apartment-card__list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 7px;
  list-style: none;
}
.col .lw-apartment-card__list--chars, .col .lw-apartment-card__list--permissions{
  margin-bottom: 10px;
}

.lw-apartment-card__list-company-price {
  display: flex;
  align-items: center;
  gap: 10px;
}



input {outline:none;}

.lw-apartment-card__list-company-price { 
  > input {
  padding: 3px 10px;
  width: 100px; 
  height: 28px;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 3px;
              &:active &:hover, &:focus {
                    border: 1px solid $gray-200;
                    background: $gray-100;
                    //text-shadow: 0px 1px 4px rgba($primary, 0.34);
                    //box-shadow: 0px 0px 3px rgba($primary, 0.15);
                }
                
              }
}

.lw-apartment-card__list-company-price span {
  font-weight: 400;
  font-size: 20px;
  color: #4caf50;
}

@media (max-width: 500px) {
  .lw-apartment-card__list-company-price {
    width: 306px;
    justify-content: flex-end;
  }
  
}

.lw-apartment-card__company-btn {
  width: 100%;
  max-width: 80px;
}

.custom-select-opener {
  position: relative;
  width: 100px;
  height: 28px;
  text-align: left;
  padding: 5px 28px 3px 10px !important;
  border: 1px solid #dedede;
  border-radius: 3px;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666666;
  /*background: #ffffff;*/
}

.custom-select-panel  {
  width: fit-content;
  margin-top: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.custom-select-opener::before {
  content: "";
  position: absolute;
  right: 10px;
  width: 18px;
  height: 18px;
  background-image: url("~@/assets/img/arrow_select.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  transition: 200ms ease;
}

.custom-select-option {
  width: 100%;
  min-width: 100px;
  height: 32px;
  text-align: left;
  padding: 7px 28px 3px 10px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  background: #ffffff;
  color: #666666;
  cursor: pointer;
}

.custom-select-option:not(:last-child) {
  border-bottom: 1px solid #dedede;
}

.lw-main-link {
  font-weight: 400;
  font-size: 16px;
  color: #4caf50;
}

.lw-main-link--icon {
  position: relative;
  padding-left: 18px;
}

.lw-main-link--icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 16px;
  max-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.lw-text-green {
  color: #4caf50;
}

.lw-text-gray {
  color: #7d7d7d;
}

.lw-text-blue {
  color: #0374ff;
}

.lw-text-white {
  color: #ffffff;
}

.lw-text-underlined {
  text-decoration: underline;
  margin-left: 5px;
  color: #000;
}

.lw-text-underlined-no {
  text-decoration: none;
  margin-left: 5px;
  color: #000;
}

.lw-apartment-card__field {
  display: flex;
  align-items: center;
  gap: 2px;
}

.lw-apartment-card__field--flex-start:first-child {
  align-items: flex-start;
  margin-right: 20px; /*clamp(50px, 5vw, 100px);*/
  gap: 15px;
}


.lw-apartment-card__field-icon-tags {
  max-width: 16px;
  max-height: 16px;
}

.lw-apartment-card__field-icon-info {
  max-width: 20px;
  max-height: 20px;
}

.lw-apartment-card.jw__object .ti-heart {
  font-size: 26px;
  color: #757575;
  cursor: pointer;
  top: 1px !important;
}

.lw-apartment-card.jw__object .ti-heart:hover {
  color: #0c2f95;
}

.lw-apartment-card.jw__object .ti-heart.active {
  color: #EE6161;
}

.red {
  color: #EE6161;
}

.lw-apartment-card__field a {
  border-bottom: none;

}

.lw-apartment-card.jw__object .actionJW {
    height: 26px;
    line-height: 26px;
}

.lw-apartment-card .jw__action-button {
  margin-right: 0px;
}

.lw-base-title {
  margin-left: 10px;
}

.lw-apartment-card__status {
  position: relative;
  padding-left: 12px !important;
  cursor: pointer;
  margin-right: 10px;
  width: 100%;
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important;
}

.lw-apartment-card__status.archive::before {
  background-color: #EE6161;
}
.lw-apartment-card__status.archive {
  color: #EE6161;
}

.lw-base-text--small {
    font-weight: 400;
    font-size: 12px;
}

.lw-apartment-card__status.active {
  color: #fd3018;
}

.lw-apartment-card__status::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 8px;
  max-height: 8px;
  border-radius: 50%;
  background-color: #4caf50;
}

.lw-apartment-card__status.active::before {
  background-color: #fd3018;
}


.lw-main-btn--no-border {
  background: none;
  border: none;
  padding: 0;
}

.lw-main-btn--icon {
  position: relative;
  padding-left: 18px;
}

.lw-main-btn--icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 16px;
  max-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.lw-main-btn img {
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
}

.lw-apartment-card__pdf-btn {
  background: linear-gradient(180deg, #ff5761 0%, #e52a35 100%);
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.lw-apartment-card__vk-btn {
  background: linear-gradient(180deg, #6b86a7 0%, #557197 100%);
}

.lw-apartment-card__extract-btn {
  border: 1px solid #0374ff;
  color: #0374ff;
}

.lw-apartment-card__promotion-btn {
  border: 1px solid #4caf50;
  color: #4caf50;
}

.lw-apartment-card__document-btn img {
  max-height: 16px;
}

.lw-apartment-card__further-btn {
  margin-right: 10px;
}

.lw-apartment-card__id {
  font-weight: 700;
  font-size: clamp(14px, 1.3vw, 16px);
  color: #757575;
}

@media (max-width: 768px) {
  .lw-apartment-card__id {
    margin-right: 5px;
  }
}

</style>

<script>

import * as bootstrap from "bootstrap";
import CommonService from "@/services/CommonService";
import ResultsModal from "@/components/modals/ResultsModal";
import ConfirmModal from "@/components/modals/ConfirmModal";
import Switcher from "@/components/inputs/Switcher";
import OrderEgrnModal from "@/components/modals/OrderEgrnModal";
import TarifsEgrnModal from "@/components/modals/TarifsEgrnModal";
import DelegateObjectModal from "@/components/modals/DelegateObjectModal";
import CancelDelegateObjectModal from "@/components/modals/CancelDelegateObjectModal";
import AdvertStatsModal from "@/components/modals/AdvertStatsModal";
import AdvertCoastModal from "@/components/modals/AdvertCoastModal";
import ObjectOnMapModal from "@/components/modals/ObjectOnMapModal";
import SetTagsModal from "@/components/modals/SetTagsModal";
import api from "@/api";
import { copyText } from 'vue3-clipboard';
import TextArea from "@/components/inputs/TextArea";
import SelectBox from "@/components/inputs/SelectBox";
import InputSelect from "@/components/inputs/InputSelect";
import Editor from "@/components/inputs/Editor";
import NewTaskModal from "@/components/modals/NewTaskModal"


export default {
  name: "ObjectCard",
  props: {
    tagsList: {type: Object},
    objectItem: {type: Object},
    section: { type: String, default: 'objects' },
    layout: { type: String, default: 'default' },
    inputFilters: { type: [Array, Object] },
    asfilterId: { type: [String, Number] },
    isChecked: {type: Boolean},
    isFavoured: {type: Boolean},
    isViewed: {type: Boolean},
    isLoadingProcess: {type: Boolean, default: true},
    isUpdatingProcess: {type: Boolean, default: false},
    isReverseProcess: {type: Boolean, default: true},
  },
  data() {
    return {
      carousels: {},
      published_packages: [],
      confirmModalState: {
        title: '',
        text: '',
        recipient: '',
        fromSection: 'objectsShortCard',
        btnText: ''
      },
      resultsModalState: {
        state: '',
        title: '',
        message: '',
      },
      list_cian_promo: [
          {
						value: 'cian_standard',
						name: 'Стандарт',
					},
          {
						value: 'cian_premium',
						name: 'Премиум-объявлени',
					},
          {
						value: 'cian_top3',
						name: 'Топ',
					},
          {
						value: 'cian_ignore_pkg',
						name: 'Не использовать пакет размещений',
					},
        ],
      list_avito_promo: [
          {
						value: 'avito_highlight',
						name: 'Выделение объявлени',
					},
          {
						value: 'avito_xl',
						name: 'XL-объявление',
					},
          {
						value: 'avito_x2_1',
						name: 'x2 просмотров на 1 день',
					},
          {
						value: 'avito_x2_7',
						name: 'x2 просмотров на 7 дней',
					},
          {
						value: 'avito_x5_1',
						name: 'x5 просмотров на 1 день',
					},
          {
						value: 'avito_x5_7',
						name: 'x5 просмотров на 7 дней',
					},
          {
						value: 'avito_x10_1',
						name: 'x10 просмотров на 1 день',
					},
          {
						value: 'avito_x10_7',
						name: 'x10 просмотров на 7 дней',
					},
      ],
      list_yandex_promo: [
          {
						value: 'yandex_premium',
						name: 'Премиум',
					},
          {
						value: 'yandex_raise',
						name: 'Поднятие',
					},
          {
						value: 'yandex_promotion',
						name: 'Продвижение',
					},
        ],
        list_domclick_promo: [
          {
						value: 'domclick_express',
						name: 'Экспресс',
					},
          {
						value: 'domclick_premium',
						name: 'Премиум',
					},
          {
						value: 'domclick_top',
						name: 'Топ',
					},
        ],
      
       
                                            
      in_moderation_list: [],
      moderators_list: [],
      adverts_moderators: [],
      moderation_errors: [],
      adverts_comment: '',
      need_show_moderation: 1,
      need_approve_moderation: false,
      is_change_cian_bet: false,
    };
  },
  methods: {
    openTask(){
      if(this.item.event_id > 0){
        this.$emit('openTask', this.item.id)
      } else {
        this.resultsModalState.state = 'error';
        this.resultsModalState.title = 'Произошла ошибка!';
        this.resultsModalState.message = 'Для смены статуса поставьте задачу "Звонок собственнику".';
      }
    },
    сollapse() {
      console.log(this.item.is_collapse);
      if(this.item){
        if(this.item.is_collapse == 1)
          this.item.is_collapse = 0;
        else 
          this.item.is_collapse = 1;
      }

    },
    declOfNum(number, titles, append) {
      return CommonService.declOfNum(number, titles, append);
    },
    copyID(object_id) {
      copyText(object_id, undefined, (error, event) => {
        if (error) {
          console.error('Не удалось скопировать ID объекта: ', error);
          alert('Не удалось скопировать ID объекта.');
        } else {
          alert('ID объекта скопирован!');
          console.log(event);
        }
      });
    },
    copyLink() {
      let link = this.item.links.for_clients;
      if (!this.isEmpty(link)) {
        copyText(link, undefined, (error, event) => {
          if (error) {
            console.error('Не удалось скопировать ссылку: ', error);
            alert('Не удалось скопировать ссылку.');
          } else {
            alert('Ссылка на кабинет клиента успешно скопирована!');
            console.log(event);
          }
        });
      }
    },
    addRemoveObjectFavour(object_id) {

      CommonService.log('log', {object_id: object_id, is_favoured: this.item.is_favoured});

      let endpoint = '/objects/favorite';
      if (this.section == 'autosearch')
        endpoint = '/autosearch/favorite';

      let data = {
        object_id: object_id,
        current: this.item.is_favoured
      };

      if (this.section == 'autosearch')
        data.filter_id = this.asfilter_id;

      api.put(endpoint, data).then((response) => {
        CommonService.log('debug', 'addRemoveObjectFavour()::axios', {response});

        if (response.status == 200 && response.data.success)
          this.item.is_favoured = response.data.state;

        this.$emit('updateObjectsList', this);
        this.$emit('addRemoveObjectFavour', object_id);

      }).catch(function (error) {

        this.item.is_favoured = !this.item.is_favoured;

        CommonService.log('error', 'addRemoveObjectFavour()::axios', error);

      });
    },
    addObjectToIgnored(object_id) {

      CommonService.log('log', {object_id: object_id, is_favoured: this.item.is_favoured});

      api.put('/autosearch/ignored', {
        object_id: object_id,
        filter_id: this.asfilter_id
      }).then((response) => {
        CommonService.log('debug', 'addRemoveObjectIgnored()::axios', {response});

        if (response.status == 200 && response.data.success) {
          if (response.data.state) {
            this.item = {};
          }
        }

        this.$emit('updateObjectsList');
        this.$emit('addObjectToIgnored', object_id);

      }).catch(function (error) {

        CommonService.log('error', 'addRemoveObjectIgnored()::axios', error);

      });
    },
    addRemoveObjectViewed(object_id) {

      CommonService.log('log', {object_id: object_id, is_viewed: this.item.is_viewed});

      api.put('/objects/viewed', {
        object_id: object_id,
        current: this.item.is_viewed
      }).then((response) => {
        CommonService.log('debug', 'addRemoveObjectViewed()::axios', {response});

        if (response.status == 200 && response.data.success)
          this.item.is_viewed = response.data.state;

        this.$emit('addRemoveObjectViewed', object_id);

      }).catch(function (error) {

        this.item.is_viewed = !this.item.is_viewed;

        CommonService.log('error', 'addRemoveObjectViewed()::axios', error);

      });
    },
    removeObjectArchive(object_id) {

      CommonService.log('log', {object_id: object_id, is_archived: this.item.is_archived});

      let need_processed = false;
      if (this.item.is_archived) {
        need_processed = true;
      }

      if (need_processed) {
        api.post('/objects/single_unarchived', {
          objects_ids: object_id,
        }).then((response) => {
          CommonService.log('debug', 'addRemoveObjectArchive()::axios', {response});

          if (response.status == 200 && response.data.success)
            this.item.is_archived = response.data.state;
          console.log(object_id);
          this.$emit('updateObjectsList');
          this.$emit('addRemoveObjectArchive');
        }).catch(function (error) {

          this.item.is_archived = !this.item.is_archived;

          CommonService.log('error', 'addRemoveObjectArchive()::axios', error);

        });
      }
    },
    setViewed(object_id, filter_id) {

      if (this.section != 'autosearch')
        return;

      if (typeof filter_id == "undefined")
        filter_id = this.asfilter_id;

      CommonService.debounce(() => {
        this.item.is_viewed = true;

        CommonService.log('log', {object_id: object_id, is_favoured: this.item.is_favoured});

        api.put('/autosearch/viewed', {
          object_id: object_id,
          filter_id: filter_id
        }).then((response) => {
          CommonService.log('debug', 'setViewed()::axios', {response});

          if (response.status == 200 && response.data.success) {
            if (response.data.state) {
              this.item = {};
            }
          }

          this.$emit('updateObjectsList', this);
          this.$emit('addRemoveObjectViewed', object_id);

        }).catch(function (error) {

          CommonService.log('error', 'setViewed()::axios', error);

        });
      }, 100);
    },
    sendObjectToAdverts(object_id, value, event) {

      CommonService.log('log', {object_id: object_id, service: 'global', status: this.item.use_in_advert});

      let to_send = 1
      if (this.item.use_in_advert && !value) {
        if (this.user_can.moderate_adverts && !this.isEmpty(this.item.moderation) && !this.item.is_moderated) {
          if (confirm('Вы действительно желаете снять объект с модерации?')) {
            this.deleteObjectFromModeration();
          }
          to_send = 0;
        } //else {
          //if (!confirm('Убрать объект из рекламы? Если показ в рекламе уже был начат, то все ротации по объекту будут остановлены.')) {
            //to_send = 0;
          //}
        //}
      }

      if (to_send) {
        api.put('/adverts/set', {
          object_id: object_id,
          service: 'global',
          current: this.item.use_in_advert
        }).then((response) => {

          //console.log(response.data);
          CommonService.log('debug', 'sendObjectToAdverts()::axios', {response});

          if (response.status == 200 && response.data.success)
            this.item.use_in_advert = response.data.state;

          this.$emit('updateObject', object_id);

        }).catch(function (error) {

          this.item.use_in_advert = !this.item.use_in_advert;

          CommonService.log('error', 'sendObjectToAdverts()::axios', error);

          this.$emit('updateObject', object_id);
          this.$emit('sendObjectToAdverts', object_id);
        });
      }
    },
    
    setupCianBet(){
      let _this = this;
      if (!this.is_change_cian_bet) {
            this.is_change_cian_bet = true;
            setTimeout(function(){
              _this.setupPromo('cian_bet');
            }, 2000)
        }
      },
    setupPromo(val){
      let params = {};
      if(val.indexOf("avito_") != -1){
            this.item.promo.avito = val;
            params.avito_highlight = (val == 'avito_highlight') ? 1 : 0;
            params.avito_xl = (val ==  "avito_xl") ? 1 : 0;
            params.avito_x2_1 = (val == "avito_x2_1") ? 1 : 0;
            params.avito_x2_7 = (val == "avito_x2_7") ? 1 : 0;
            params.avito_x5_1 = (val == "avito_x5_1") ? 1 : 0;
            params.avito_x5_7 = (val == "avito_x5_7") ? 1 : 0;
            params.avito_x10_1 = (val == "avito_x10_1") ? 1 : 0;
            params.avito_x10_7 = (val == "avito_x10_7") ? 1 : 0;
        }

        if(val.indexOf("cian_") != -1 && val != 'cian_bet'){
            this.item.promo.cian = val;
            params.cian_highlight = (val == 'cian_highlight') ? 1 : 0;
            params.cian_standard = (val == 'cian_standard') ? 1 : 0;
            params.cian_paid = (val == 'cian_paid') ? 1 : 0;
            params.cian_premium = (val == 'cian_premium') ? 1 : 0;
            params.cian_top3 = (val == 'cian_top3') ? 1 : 0;
            params.cian_ignore_pkg = (val == 'cian_ignore_pkg') ? 1 : 0;
        }
        
        if(val == 'cian_bet'){
            let price = this.item.promo.cian_bet;
            if(this.isEmpty(price)) price = 0;
            params.cian_bet = price;
        }

        if(val.indexOf("yandex_") != -1) {
            this.item.promo.yandex = val;
            params.yandex_premium = (val == "yandex_premium") ? 1 : 0;
            params.yandex_raise = (val == "yandex_raise") ? 1 : 0;
            params.yandex_promotion = (val == "yandex_promotion") ? 1 : 0;
        }

        if(val.indexOf("domclick_") != -1) {
            params.domclick_express = (val == "domclick_express") ? 1 : 0;
            params.domclick_premium = (val == "domclick_premium") ? 1 : 0;
            params.domclick_top = (val == "domclick_top") ? 1 : 0;
        }
        
        api.put('/adverts/promotion', {
						object_id: this.item.id,
						packages: params
					}).then((response) => {
						//console.log(response.data);
						CommonService.log('debug', 'addObjectPromo()::axios', {response});
            this.is_change_cian_bet = false;
						if (response.status == 200 && response.data.success)
							this.$emit('updateObject', this.item.id);

					}).catch(function (error) {

						//CommonService.log('error', 'addObjectPromo()::axios', error);

					});
    },
    addRemoveObjectAdverts(object_id, service, event) {

      var trigger = event.currentTarget;
      trigger.classList.add('disable');

      if (!this.isEmpty(this.item.services.advertised)) {

        let state = this.item.services.advertised[service];
        CommonService.log('info', 'addRemoveObjectAdverts()', {object_id: object_id, service: service, state: state});
        this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: !state});

        api.put('/adverts/set', {
          object_id: object_id,
          service: service,
          current: state
        }).then((response) => {
          CommonService.log('debug', 'addRemoveObjectAdverts()::axios', {response});

          if (response.status == 200 && response.data.success) {
            this.item.services.advertised[service] = response.data.state;
            this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: response.data.state});
          }

          trigger.classList.remove('disable');
          this.$emit('updateObject', object_id);
        }).catch(function (error) {

          this.item.services.advertised[service] = !this.item.services.advertised[service];
          trigger.classList.remove('disable');
          CommonService.log('error', 'addRemoveObjectAdverts()::axios', error);

          this.$emit('updateObject', object_id);
          this.$emit('addRemoveObjectAdverts', object_id);
        });
      }
    },
    setAndValidate(name, value, skip_advert_check) {

      let errors = [];
      let parent = null;
      let child = null;
      let parts = name.split('.', 2);
      if (parts.length == 2) {
        parent = parts[0];
        child = parts[1];
      }

      skip_advert_check = false;
      if (typeof skip_advert_check != "undefined")
        skip_advert_check = true;

      // Валидация значения
      if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
        /*if ('invalid' in this.$refs[name])
          this.$refs[name].invalid = false;*/

        if (this.$refs[name].inputLabel) {
          let label = this.$refs[name].inputLabel.replace(/:+$/, '');

          let is_skip = false;
          /*if (name == 'funnel_id' && value == 0)
            is_skip = true;*/

          if (this.$refs[name].required && !is_skip) {
            if (value) {
              if (this.$refs[name].required && value.toString() == '0')
                errors.push('Поле `' + label + '` обязательно к заполнению!');
              else if (this.$refs[name].required && this.isEmpty(value))
                errors.push('Поле `' + label + '` обязательно к заполнению!');
            } else {
              errors.push('Поле `' + label + '` обязательно к заполнению!');
            }

            if (
              this.inArray(name, ['deal_type', 'avito_room_type_id', 'address.country_id', 'house_material_type', 'house_type']) &&
              value === 'Не выбрано') {
              errors.push('Поле `' + label + '` обязательно к заполнению!');
              value = null;
            }
          }

          if (name == 'metro.type' && this.isEmpty(value) && !this.isEmpty(this.item.metro.time)) {
            errors.push('Поле `' + label + '` обязательно к заполнению!');
          }

          if (name == 'photos') {
            errors.push('Поле `' + label + '` обязательно к заполнению!');
          }

          if (!this.isEmpty(errors)) {

            this.errors[name] = {
              name: name,
              label: label,
              errors: errors
            };

            this.$refs[name].valid = false;
            this.$refs[name].invalid = errors.join('<br/>');
          } else {
            this.$refs[name].valid = true;
            this.$refs[name].invalid = false;
          }
        }
      }

      // Проверки перед установкой значения
      if (name) {

        if (name == 'address.full' && typeof value === "string" && !this.isEmpty(value)) {
          value = value.replace(/^\d{6,},/g, ''); // чистим от почтового индекса (dadata.ru)
        }

        if (name == 'operation_type' && value == 1)
          this.item.lease_type = 2;
        else if (name == 'operation_type' && value !== 2)
          this.item.lease_type = null;

        if ((name == 'geo.lat' || name == 'geo.lng') && !this.isEmpty(value))
          value = parseFloat(value);

        if (name == 'object_type' && value == 4)
          this.item.commercial_type = 1;
        else if (name == 'object_type' && value !== 4)
          this.item.commercial_type = null;

        if (name == 'address.region_id') {
          this.item.metro = null;
          this.item.to_metro = null;
          this.metro_list = null;
          this.getMetroList(value).then(data => {

            if (!this.isEmpty(data))
              this.metro_list = data;
            else
              this.metro_list = null;

          });

          this.getNewBuildings(value).then(data => {
            if (!this.isEmpty(data))
              this.newbuildings_list = this.sortArray(data)
            else
              this.newbuildings_list = null;
          });
        }

        if (this.inArray(name, ['prices.amount', 'prices.amount_commission', 'prices.prep', 'prices.start', 'prices.fact', 'prices.price_avito'])) {
          if (typeof value === "string" && !this.isEmpty(value)) {
            value = parseInt(value.replace(/\s/g, ''));
          }
        }

        if (!skip_advert_check && this.item.id && (name == 'use_in_advert' && value == 0)) {
          let confirm = CommonService.getModal('addEditObjectConfirmRemoveFromAds');
          confirm.show();
        }
      }

      // Установка значения
      if (parent && child) {
        if (!this.isEmpty(this.item[parent])) {
          if (typeof (this.item[parent][child]) !== "undefined") {
            this.item[parent][child] = value;
          } else {
            this.item[parent] = {
              ...{
                [child]: value
              },
              ...this.item[parent]
            };
          }
        } else {
          this.item[parent] = {
            [child]: value
          };
        }
      } else if (parent == null && name) {
        /*if (name == 'fields') {
          return;
        } else {
          this.item[name] = value;
        }*/
        this.item[name] = value;
      } else {
        if (parent && child)
          this.item[parent][child] = null;
        else
          this.item[name] = null;
      }

      this.is_form_changed = true;

      CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });



      if (!this.isEmpty(errors))
        return false;

      return true;
    },
    deleteObject() {
      this.item.is_deleted = true;
      this.$emit('objectsChanged');
      this.$emit('deleteObject', this.item.id);
    },
    getPublishedPackages(object_id) {
      return api.get('/adverts/zipal/published', {
        params: {
          object_id: object_id
        }
      })
          .then((response) => {

            CommonService.log('debug', 'getPublishedPackages()::axios', {response: response.data});

            if (response.status == 200 && response.data.success) {
              if (!this.isEmpty(response.data.packages)) {
                return response.data.packages;
              }
            }

            return false;
          }).catch(function (error) {

            CommonService.log('error', 'getPublishedPackages()::axios', error);

            return false;
          });
    },
    getObjectPhotos() {
      api.get('/object/photos', {
        params: {
          object_id: this.item.id,
          compressed: true
        },
        responseType: 'blob'
      })
          .then((response) => {

            CommonService.log('debug', 'getObjectPhotos()::axios', {status: response.status, contentType: response.headers['content-type']});

            if (response.status == 200 && response.headers['content-type'] == "application/zip") {
              const blob = new Blob([response.data], { type: "application/zip" });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('target', '_blank');
              link.setAttribute('download', this.item.address + '_' + this.item.id + '.zip');
              document.body.appendChild(link);
              link.click();
              setTimeout(() => link.remove(), 600);
            }
          }).catch(function (error) {

        CommonService.log('error', 'getObjectPhotos()::axios', error);

      });
    },

    getModerators(object_id, use_in_advert) {

      let _this = this;
      _this.item.use_in_advert = use_in_advert;

      setTimeout(() => {
        if (use_in_advert)
          this.need_show_moderation = 0;
        else
          this.need_show_moderation = 1
      }, 1000);

      if (use_in_advert == 0) {
        if (confirm('Вы действительно желаете снять объект с модерации?')) {
          _this.deleteObjectFromModeration();
        }
      }

      if (!this.inArray(object_id, this.in_moderation_list)) {
        this.in_moderation_list.push(object_id);
      } else {

        let index = this.in_moderation_list.indexOf(object_id);
        if (index != -1)
          this.in_moderation_list.slice(index, 1);

      }

      console.info(this.in_moderation_list);

      return api.get('/adverts/moderators', {
        params: {
          object_id: object_id,
          section: 'adverts'
        }
      })
          .then((response) => {

            CommonService.log('debug', 'getModerators()::axios', {response: response.data});

            if (response.status == 200 && response.data.success) {

              let list = [];
              if (response.data.list) {
                Object.entries(response.data.list).forEach(([key, employee]) => {
                  list.push({
                    value: employee.id.toString(),
                    name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
                  });
                });
              }

              _this.moderators_list = list
              return list;
            } else {
              return {};
            }
          }).catch(function (error) {

            CommonService.log('error', 'getModerators()::axios', error);

          });
    },
    copyToMyObjects() {
      api.post('/objects/copy', {
        object_id: this.item.id
      }).then((response) => {
        CommonService.log('debug', 'copyToMyObjects()::axios', {response});

        if (response.status == 200 && response.data.success) {
          this.resultsModalState.state = 'success';
          this.resultsModalState.title = 'Объект скопирован в ваши объекты!';
          this.resultsModalState.message = 'Вы сможете увидеть его, перейдя в меню Мои объекты.';
        } else {
          this.resultsModalState.state = 'error';
          this.resultsModalState.title = 'Произошла ошибка!';
          this.resultsModalState.message = 'Объект не был скопирован.';
        }
        /*if (response.status == 200 && response.data.success)
          this.item.services.advertised[service] = response.data.state;*/

      }).catch(function (error) {

        CommonService.log('error', 'copyToMyObjects()::axios', error);

        this.resultsModalState.state = 'error';
        this.resultsModalState.title = 'Произошла ошибка!';
        this.resultsModalState.message = 'Объект не был скопирован.';

      });
    },
    formatPrice(value, currency, sign) {
      return CommonService.formatPrice(value, currency, sign);
    },
    formatPhone(value, link) {
      return CommonService.formatPhoneNumber(value, link);
    },
    formatDateTime(datetime, locale, format) {
      return CommonService.formatDateTime(datetime, locale, format);
    },
    buildTagsList(tags, tags_list) {
      let list = CommonService.resetProxy(tags_list);
      if (tags && list) {
        list.find((item, i) => {
          if (tags.indexOf(item.id) !== -1) {
            list[i].isChecked = true;
          } else {
            list[i].isChecked = false;
          }
        });
      }

      return list;
    },
    fillConfirmModalState(action) {
      if (action === 'deleteObject') {
        this.confirmModalState.title = 'Удаление объекта';
        this.confirmModalState.text = 'Вы действительно желаете удалить этот объект!?';
        this.confirmModalState.recipient = this.item.id;
        this.confirmModalState.fromSection = 'objectsShortCard';
        this.confirmModalState.btnText = 'Удалить';
      }
    },
    setConfirmModeration() {

      let _this = this;
      api.post('/adverts/moderation/confirm', {
        object_id: _this.item.id,
        employee_ids: _this.adverts_moderators,
        comment: _this.adverts_comment,
        services: _this.item.services
      }).then((response) => {

        CommonService.log('debug', 'setConfirmModeration()::axios', {response});

        if (response.status == 200 && response.data.success) {
          _this.need_approve_moderation = false;
          _this.item.services.is_moderated = true;
          _this.$emit('updateObject', _this.item.id);
          setTimeout(() => {
            let target = _this.$refs.orderAdvertsPopover.$el;
            let popover = bootstrap.Popover.getInstance(target);
            if (popover) {
              popover.hide();
            }
          }, 5000);
        } else if (!_this.isEmpty(response.data.errors)) {
          _this.moderation_errors = response.data.errors;
        }
      }).catch(function (error) {

        CommonService.log('error', 'setConfirmModeration()::axios', error);

        _this.need_approve_moderation = true;
        _this.item.services.is_moderated = false;
      });
    },
    deleteObjectFromModeration() {
      api.delete('/adverts/moderation', {
        params: {
          object_id: this.item.id
        }
      }).then((response) => {

        CommonService.log('debug', 'deleteObjectFromModeration()::axios', {response});

        if (response.status == 200 && response.data.success) {
          this.item.services.is_moderated = false;
          this.$emit('updateObject', this.item.id);
          this.item = {...this.item, ...{
              use_in_advert: false,
              moderation: []
            }
          };
          //this.$emit('addRemoveObjectAdverts', this.item.id);
        }
      }).catch(function (error) {

        CommonService.log('error', 'deleteObjectFromModeration()::axios', error);

      });
    },
    addObjectToModeration() {
      let _this = this;
      api.put('/adverts/moderation', {
        object_id: _this.item.id,
        employee_ids: _this.adverts_moderators,
        comment: _this.adverts_comment
      }).then((response) => {

        CommonService.log('debug', 'addObjectToModeration()::axios', {response});

        if (response.status == 200 && response.data.success) {

          _this.item.services.is_moderated = true;

          _this.$emit('updateObject', _this.item.id);

          /*_this.item = {..._this.item, ...{
              moderation: {
                status: 'pending'
              }
            }
          };*/

          _this.$emit('addRemoveObjectAdverts', _this.item.id);

          setTimeout(() => {
            let target = _this.$refs.orderAdvertsPopover.$el;
            let popover = bootstrap.Popover.getInstance(target);
            if (popover) {
              popover.hide();
            }
          }, 5000);
        }
      }).catch(function (error) {

        CommonService.log('error', 'addObjectToModeration()::axios', error);

      });
    },
    isEmpty(data) {
      return CommonService.isEmpty(data);
    },
    inArray(needle, haystack) {
      return CommonService.inArray(needle, haystack);
    },
    isCheckedForPDF(object_id) {
      return this.inArray(object_id, this.$store.state.to_pdf);
    },
    icon(path, class_name, title, size) {
				return CommonService.buildIconImage(path, class_name, title, size);
			},
  },
  components: {
    SelectBox,
    TextArea,
    ResultsModal,
    ConfirmModal,
    Switcher,
    InputSelect,
    Editor,
    
  },
  mounted() {
    
    let _this = this;
    setTimeout(function(){
        if(_this.item.id && document.getElementById('objectThumbnails' + _this.item.id)){
            _this.carousels[_this.item.id] = document.getElementById('objectThumbnails' + _this.item.id)
          new bootstrap.Carousel(_this.carousels[_this.item.id]);
        }
    }, 500);

    let documentel = this.$el;
    CommonService.popoversInit(documentel);

    /*this.$nextTick(() => {
      if (!this.isEmpty(this.item.moderation)) {
        if (!this.item.services.is_moderated && !this.isEmpty(this.item.moderation.advertised)) {
          this.item.services.advertised.packages = this.item.moderation.advertised.packages ?? false;
          this.item.services.advertised.free = this.item.moderation.advertised.free ?? false;
          this.item.services.advertised.avito = this.item.moderation.advertised.avito ?? false;
          this.item.services.advertised.yandex = this.item.moderation.advertised.yandex ?? false;
          this.item.services.advertised.cian = this.item.moderation.advertised.cian ?? false;
          this.item.services.advertised.domclick = this.item.moderation.advertised.domclick ?? false;
          this.item.services.advertised.zipal = this.item.moderation.advertised.zipal ?? false;
          this.item.services.advertised.jcat = this.item.moderation.advertised.jcat ?? false;
        }

        this.need_approve_moderation = true;
      }
    });*/
  },
  watch: {
    'use_in_advert': function(val, oldVal) {
      if (this.user_can.add_to_adverts && this.user_can.add_to_moderation) {
        if (val === 0) {
          let target = this.$refs.orderAdvertsPopover.$el;
          let popover = bootstrap.Popover.getOrCreateInstance(target);
          if (popover) {
            popover.hide();
          }
        }
      }
    },
    'item': function(val, oldVal) {
      if (val.services.advertised.packages) {
        this.getPublishedPackages(val.id).then(data => {
          this.published_packages = data;
        });
      }
    },
  },
  computed: {
    item() {
      return this.$props.objectItem ?? {};
    },
    use_in_advert() {
      return this.item.use_in_advert;
    },
    is_moderation() {
      return (!this.isEmpty(this.item.moderation) && this.$store.getters.userInfo.user_can.add_to_moderation || this.$store.getters.userInfo.user_can.adverts_moderate) ?? false;
    },
    action_menu_show() {
      return (
          this.user_can.ads_report ||
          this.user_can.publish_report ||
          this.user_can.payment_report ||
          this.user_can.cabinet ||
          this.user_can.edit ||
          this.user_can.copy ||
          this.user_can.requisition ||
          this.user_can.docs ||
          this.user_can.delete ||
          this.user_can.send_complaint
      );
    },
    floors() {
      let floors = null;
      if (this.item.object_type != 7 && !this.inArray(this.item.commercial_type, [7, 9])) {
        floors = this.item.floors.num;
        if (this.item.object_type == 3 || (this.item.object_type == 4 && this.item.commercial_type == 6)) {
          floors = 'Этажей: ' + this.item.floors.max;
        } else if (!this.inArray(this.item.object_type, [3, 5]) && !this.inArray(this.item.commercial_type, [6])) {
          floors = 'Этаж: ' + floors + ' из ' + this.item.floors.max;
        }
      }

      return floors;
    },
    advert_error() {

      if (!this.isEmpty(this.item.services.errors))
        return this.item.services.errors;

      return false;
    },
    zipal_error() {

      if (!this.isEmpty(this.item.services.errors))
        return this.item.services.errors.zipal;

      return false;
    },
    packages_error() {

      if (!this.isEmpty(this.item.services.errors))
        return this.item.services.errors.packages;

      return false;
    },
    is_adverts_setup() {

      if (!this.isEmpty(this.item.services.advertised)) {
        return (
            this.item.services.advertised.packages ||
            this.item.services.advertised.free ||
            this.item.services.advertised.avito ||
            this.item.services.advertised.yandex ||
            this.item.services.advertised.cian ||
            this.item.services.advertised.domclick ||
            this.item.services.advertised.zipal ||
            this.item.services.advertised.jcat
        );
      }

      return false;
    },
    moderation_status() {

      if (!this.isEmpty(this.item.moderation))
        return this.item.moderation.status;

      return false;
    },
    photo_count() {
      if (!this.isEmpty(this.item.photos))
        return this.item.photos.length;

      return 0;
    },
    country_id() {
      if (!this.isEmpty(this.item.country_id))
        return parseInt(this.item.country_id);

      return null;
    },
    asfilter_id() {
      return this.$props.asfilterId ?? null;
    },
    is_checked() {
      return (typeof this.$props.isChecked !== "undefined") ? this.$props.isChecked : false;
    },
    is_viewed() {
      return (typeof this.$props.isViewed !== "undefined") ? this.$props.isViewed : false
    },
    is_loading_process() {
      return (typeof this.$props.isLoadingProcess !== "undefined") ? this.$props.isLoadingProcess : true
    },
    is_updating_process() {
      return (typeof this.$props.isUpdatingProcess !== "undefined") ? this.$props.isUpdatingProcess : false
    },
    is_reverse_process() {
      return (typeof this.$props.isReverseProcess !== "undefined") ? this.$props.isReverseProcess : true
    },
    user_can() {
      return {
        view: {
          ads_report: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && (this.item.rent_id > 0 || this.item.add_user_id > 0)),
          publish_report: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && (this.item.add_to_zipal == 1 && this.item.add_user_id > 0)),
          payment_report: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && (this.item.add_user_id > 0/* && this.item.use_in_advert*/)),
          cabinet: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0),
          analytics: (this.section !== 'autosearch' && (this.inArray(this.$props.layout, ['default', 'adverts', 'search'])) && this.inArray(this.item.object_type, [1, 2, 4])), // Аналитика по конкурентам
          clients: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && !this.item.is_archived), // Клиент
        },
        docs: (this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0),
        copy: (this.inArray(this.$props.layout, ['default', 'adverts'])),
        requisition: (this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0), // Создать заявку
        add: (this.inArray(this.$props.layout, ['search']) && this.item.add_user_id == 0), // Добавить в мои объекты
        transfer: (this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0), // Передать сотруднику
        archive: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'short', 'adverts']) && this.item.add_user_id > 0), // Добавить в архив
        autosearch: (this.country_id == 171 && (this.inArray(this.$props.layout, ['default', 'adverts', 'search']) && this.$store.getters.userInfo.user_can.autosearch)), // Заявки по автопоиску
        vk_post: (this.$store.getters.userInfo.user_can.vk_post), // Публиковать в ВК
        download_photo: this.$props.layout !== 'short' && this.photo_count > 0, // Скачать архив с фото
        notes: (this.inArray(this.$props.layout, ['default', 'adverts', 'search'])), // Примечания по объекту
        egrn_reports: (this.country_id == 171 && (this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0)), // Выписка ЕГРН
        tasks: (this.inArray(this.$props.layout, ['default', 'adverts', 'search'])), // Задачи по объекту
        publish: (this.inArray(this.$props.layout, ['default', 'adverts', 'search'])), // Опубликовать в ВК
        send_presentation: (this.inArray(this.$props.layout, ['default', 'adverts', 'search'])), // Отправить в презентацию
        adverts: this.$store.getters.userInfo.user_can.adverts && (this.inArray(this.$props.layout, ['default', 'adverts']) && !this.item.is_archived && this.item.add_user_id > 0), // Реклама
        add_to_adverts: this.$store.getters.userInfo.user_can.add_to_adverts && (this.inArray(this.$props.layout, ['default', 'adverts']) && !this.item.is_archived && this.item.add_user_id > 0), // В рекламу
        add_to_moderation: this.$store.getters.userInfo.user_can.add_to_moderation && (this.inArray(this.$props.layout, ['default', 'adverts']) && !this.item.is_archived && this.item.add_user_id > 0), // Отправка рекламы на модерацию
        moderate_adverts: this.$store.getters.userInfo.user_can.adverts_moderate && (this.inArray(this.$props.layout, ['default', 'adverts'])  && !this.item.is_archived && this.item.add_user_id > 0), // Модерация объекта в рекламе
        tags: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.add_user_id > 0),
        edit: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'short', 'adverts']) && this.item.add_user_id > 0),
        delete: (this.section !== 'autosearch' && this.inArray(this.$props.layout, ['default', 'adverts']) && this.item.is_archived && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin)),
        send_complaint: (this.$props.layout == 'search' && this.filters.search_target !== 5),
        advertise: {
          free: (this.$store.getters.userInfo.user_can.advertise.free),
          avito: (this.$store.getters.userInfo.user_can.advertise.avito),
          cian: (this.$store.getters.userInfo.user_can.advertise.cian),
          zipal: (this.$store.getters.userInfo.user_can.advertise.zipal),
          domclick: (this.$store.getters.userInfo.user_can.advertise.domclick),
          emls: (this.$store.getters.userInfo.user_can.advertise.emls),
          jcat: (this.$store.getters.userInfo.user_can.advertise.jcat),
          yandex: (this.$store.getters.userInfo.user_can.advertise.yandex),
          promotion: (this.$store.getters.userInfo.user_can.advertise.promotion),
        }
      };
    },
    filters() {
      let filters = (typeof this.$props.inputFilters !== "undefined") ? this.$props.inputFilters : [];
      CommonService.log('debug', 'watch::filters', filters);
      return filters;
    },
    show_order_adverts() {

      let to_showed = true;
      if (this.item.use_in_advert)
        to_showed = false;

      this.$nextTick(() => {
        to_showed = (!this.item.services.is_moderated);
      });

      return !to_showed;
    },
    tags() {

      let tags = null;
      let selected = this.item.tags;
      if (!this.isEmpty(selected))
        tags = this.buildTagsList(selected, this.$props.tagsList);
      else
        tags = this.$props.tagsList;

      return tags;
    },
    checked_tags() {

      let tags = null;
      if (!this.isEmpty(this.tags))
        tags = this.tags.filter(tag => tag.isChecked);

      return tags;
    },
    egrn_reports() {

      if (typeof this.item.services.egrn_reports != "undefined") {
        let reports = Object.entries(this.item.services.egrn_reports);
        if (typeof reports == "object") {
          return {
            count: reports.length,
            done: reports.filter((report) => Boolean(report[1])).length ?? 0
          };
        }
      }

      return {
        count: 0,
        done: 0
      };
    },
    onmore_tools_buttons() {

      if (
          this.user_can.send_presentation ||
          (this.user_can.publish && this.user_can.vk_post) ||
          this.user_can.download_photo ||
          this.user_can.tasks ||
          this.user_can.view.clients ||
          this.user_can.autosearch ||
          this.user_can.add ||
          this.user_can.notes ||
          (!this.item.is_archived && this.user_can.archive) ||
          this.user_can.archive ||
          this.user_can.transfer ||
          (this.$props.layout !== 'search' && !this.egrn_reports.count && this.user_can.egrn_reports) ||
          (this.$props.layout !== 'search' && this.egrn_reports.count && this.user_can.egrn_reports) ||
          (this.$props.layout !== 'search' && this.user_can.egrn_reports) ||
          this.user_can.adverts ||
          (this.user_can.adverts && !this.isEmpty(this.item.services.advertised)) ||
          this.user_can.adverts ||
          (!this.isEmpty(this.item.owners) && this.section !== 'autosearch')
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
